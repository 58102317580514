import React from 'react';

const ResultAPIsIntegration = () => {

  return (
    <div>
    <h3 className="darkText">APIs to Fetch Scan Results in JSON Format</h3>
    <hr/>

    <h4 className="darkText">SAST Scan Result</h4>
    <table>
      <thead>
        <tr>
          <th style={{padding:10}}>Endpoint1</th>
          <th style={{padding:10}}>Method</th>
          <th style={{padding:10}}>Path Parameter</th>
          <th style={{padding:10}}>Headers</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>api/v1/integrations/getSastScanResult/:scanId</td>
          <td>GET</td>
          <td><code>:scanId</code> - Replace with actual ID</td>
          <td>
            <ul style={{color:'#fff'}}>
              <li style={{color:'#fff'}}><strong>Client-ID:</strong> Your_Client_ID</li>
              <li style={{color:'#fff'}}><strong>Client-Secret:</strong> Your_Client_Secret</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <p>Example using <code>curl</code>:</p>
    <pre>
      {`curl -X GET \\
'https://appsecops-api.intruceptlabs.com/api/v1/integrations/getSastScanResult/65e082ec11a93bdd5aeb1db2' \\
-H 'Client-ID: Your_Client_ID' \\
-H 'Client-Secret: Your_Client_Secret'`}
    </pre>

    <h4>SCA Scan Result</h4>
    <table>
      <thead>
        <tr>
          <th style={{padding:10}}>Endpoint</th>
          <th style={{padding:10}}>Method</th>
          <th style={{padding:10}}>Path Parameter</th>
          <th style={{padding:10}}>Headers</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>api/v1/integrations/getScaScanResult/:scanId</td>
          <td>GET</td>
          <td><code>:scanId</code> - Replace with actual ID</td>
          <td>
            <ul style={{color:'#fff'}}>
              <li style={{color:'#fff'}}><strong>Client-ID:</strong> Your_Client_ID</li>
              <li style={{color:'#fff'}}><strong>Client-Secret:</strong> Your_Client_Secret</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <p>Example using <code>curl</code>:</p>
    <pre>
      {`curl -X GET \\
'https://appsecops-api.intruceptlabs.com/api/v1/integrations/getScaScanResult/65e560a1ccde5c9aa463f5ca' \\
-H 'Client-ID: Your_Client_ID' \\
-H 'Client-Secret: Your_Client_Secret'`}
    </pre>

    <h4>DAST Scan Result</h4>
    <table>
      <thead>
        <tr>
          <th style={{padding:10}}>Endpoint</th>
          <th style={{padding:10}}>Method</th>
          <th style={{padding:10}}>Path Parameter</th>
          <th style={{padding:10}}>Headers</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>api/v1/integrations/getDastScanResult/:scanId</td>
          <td>GET</td>
          <td><code>:scanId</code> - Replace with actual ID</td>
          <td>
            <ul style={{color:'#fff'}}>
              <li style={{color:'#fff'}}><strong>Client-ID:</strong> Intrucept_Client_ID</li>
              <li style={{color:'#fff'}}><strong>Client-Secret:</strong> Intrucept_Client_Secret</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <p>Example using <code>curl</code>:</p>
    <pre>
      {`curl -X GET \\
'https://appsecops-api.intruceptlabs.com/api/v1/integrations/getDastScanResult/66334d8d1a3917767894d74a' \\
-H 'Client-ID: Your_Client_ID' \\
-H 'Client-Secret: Your_Client_Secret'`}
    </pre>

    <h3>Summary</h3>
    <p>Use the provided tables to understand the endpoints, method,
       path parameters, and headers required for fetching scan results using 
       <code>curl</code> commands. Replace placeholders like <code>https://appsecops-api.intruceptlabs.com</code>, <code>Intrucept_Client_ID</code>, and <code>Intrucept_Client_Secret</code> with actual values specific to your setup.</p>
  </div>
  );
};

export default ResultAPIsIntegration;