import React from 'react';

const EclipseIntegration = () => {

  return (
    <div className="sast-scan-instructions">
      Eclipse
    </div>
  );
};

export default EclipseIntegration;