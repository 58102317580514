import React from 'react';

const IntellijIntegration = () => {

  return (
    <div className="sast-scan-instructions">
       Intellij
    </div>
  );
};

export default IntellijIntegration;