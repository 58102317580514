import React from 'react';

const VisualStudioIntegration = () => {

  return (
    <div className="sast-scan-instructions">
       Visual Studio
    </div>
  );
};

export default VisualStudioIntegration;