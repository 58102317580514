import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { FaRegCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import CICDIntegrations from './cicdIntegrations'
import IDEIntegrations from './ideIntegrations'
import TicketingIntegrations from './ticketingIntegrations'

import DockerIntegration from './dockerIntegration'
import ResultAPIsIntegration from './resultAPIs'

import { CopyToClipboard } from 'react-copy-to-clipboard';

const Integrations = () => {

  const navigate = useNavigate()
  const [organization, setOrganization] = useState({})
  const [onLoading, setOnLoading] = useState(false);

  const [clientId, setClientId] = useState('');
  const [clientIdCopied, setClientIdCopied] = useState(false);

  const [clientSecret, setClientSecret] = useState('');
  const [clientSecretCopied, setClientSecretCopied] = useState(false);

  const toaster = useRef()

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {

    window.scrollTo(0, 0);

    loadOrganizationDetails();

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadOrganizationDetails = async (theScanId) => {

    setOnLoading(true);

    const data = {
      scanId: theScanId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/organizations/getOrganizationDetails/', {
      headers: { Authorization: `Bearer ${token}` },
    });

    setOrganization(response.data.organization);
    setClientId(response.data.organization.clientId)
    setClientSecret(response.data.organization.clientSecret)

    setOnLoading(false);

  };


  const notifyClientIdCopied = async () => {

    toast('Client ID copied to the clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }

  const notifyClientSecretCopied = async () => {

    toast('Client secret copied to clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }



  return (

    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '100%' }}>
        <div>
          <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <h2 style={{ color: '#fff', fontWeight: 'bold' }}>Integrations</h2>
            <hr />

            {onLoading ?
              <ShimmerTable row={0} col={2} />
              :

              <div style={{ display: 'flex', flexDirection: 'row' }}>

                <div style={{ width: '60%' }}>


                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ fontWeight: 'bold' }}>Client ID :</span> <span style={{ marginLeft: 10 }}>{clientId}</span>
                    <CopyToClipboard text={clientId}
                      onCopy={() => notifyClientIdCopied()}>

                      <FaRegCopy color="#fff" style={{ cursor: 'pointer', marginLeft: 20 }} />

                    </CopyToClipboard>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ fontWeight: 'bold' }}>Client Secret :</span> <span style={{ marginLeft: 10 }}>{clientSecret}</span>
                    <CopyToClipboard text={clientSecret}
                      onCopy={() => notifyClientSecretCopied()}>

                      <FaRegCopy color="#fff" style={{ cursor: 'pointer', marginLeft: 20 }} />

                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            }


          </div>

        </div>


        <Tabs className="addAgentPage">

          <TabList style={{ borderWidth: 0, display: 'flex', justifyContent: 'flex-start' }}>
            <Tab style={{ width: 200, borderWidth: 0, textAlign: 'center' }}>
              <span style={{ color: '#fff', }}>CI/CD Integrations</span>
            </Tab>

            <Tab style={{ width: 200, borderWidth: 0, textAlign: 'center' }}>
              <span style={{ color: '#fff', }}>IDE Integrations</span>
            </Tab>

            <Tab style={{ width: 200, borderWidth: 0, textAlign: 'center' }}>
              <span style={{ color: '#fff', }}>Ticketing Integrations</span>
            </Tab>

            <Tab style={{ width: 200, borderWidth: 0, textAlign: 'center' }}>
              <span style={{ color: '#fff', }}>Docker Integrations</span>
            </Tab>

            <Tab style={{ width: 200, borderWidth: 0, textAlign: 'center' }}>
              <span style={{ color: '#fff' }}>Scan Result JSON API</span>
            </Tab>

          </TabList>

          {/* CI/CD Integrations  */}
          <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

           
              <CICDIntegrations/>



          </TabPanel>
          {/* END - CI/CD Integrations  */}


          {/* IDE Integrations  */}
          <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <IDEIntegrations/>


          </TabPanel>
          {/* END - IDE Integrations  */}



          {/* Ticketing Integrations  */}
          <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <TicketingIntegrations/>

          </TabPanel>
          {/* END -Ticketing Integrations */}


           {/* Ticketing Integrations  */}
           <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <DockerIntegration/>

            </TabPanel>
            {/* END -Ticketing Integrations */}


          {/* Scan Result JSON API  */}
          <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

            <ResultAPIsIntegration />

          </TabPanel>
          {/* END - Scan Result JSON API  */}


        </Tabs>
      </div>
    </div>
  )
}

export default Integrations