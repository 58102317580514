import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import '../style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineApps } from "react-icons/md";
import { MdOutlineFindInPage } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";

import Chart from 'react-apexcharts';

const ApplicationFindingsByType = () => {



  const [findings, setFindings] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);

  
  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/users/getApplicationFindingsByType`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setFindings(response.data)

    setOnLoading(false);
  };
 

  console.log('findings:', findings)

  return (
    <>

     

<div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '100%'
          }}>


            <span style={{ fontSize: 15 }}>APPLICATION FINDINGS BY TYPE</span>
            <hr style={{ borderColor: '#fff' }} />


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CgWebsite size={50} style={{ color: 'rgb(115, 102, 255)' }} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>{findings.websites}</div>
                  <span style={{ fontSize: 13 }}>Websites</span>
                </div>


              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <FaMobileAlt size={50} style={{ color: '#FFA500' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: '#FFA500', fontSize: 25 }}>{findings.mobileApps}</div>
                  <span style={{ fontSize: 13 }}>Mobile Apps</span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CiDesktop size={50} style={{ color: 'rgb(247, 49, 100)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(247, 49, 100)', fontSize: 25 }}>{findings.desktopApps}</div>
                  <span style={{ fontSize: 13 }}>Desktop Apps</span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <MdMiscellaneousServices size={50} style={{ color: 'rgb(161, 45, 210)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(161, 45, 210)', fontSize: 25 }}>{findings.others}</div>
                  <span style={{ fontSize: 13 }}>Others</span>
                </div>


              </div >


            </div>



          </div>
    </>
  )
}

export default ApplicationFindingsByType