import React from 'react';

const MavenGitHubActionsIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitHub Actions Integration Instructions for Apache Maven with SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <ol>
        <li>Go to your GitHub repository.</li>
        <li>Click on <strong>Settings</strong>.</li>
        <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong>.</li>
        <li>Click on <strong>New repository secret</strong>.</li>
        <li>Add secrets for <strong>INTRUCEPT_CLIENT_ID</strong>, <strong>INTRUCEPT_CLIENT_SECRET</strong>, and <strong>INTRUCEPT_APPLICATION_ID</strong>.</li>
      </ol>

      <h4 className="darkText">Step 2: Set up your pom.xml file</h4>
      <p>Ensure your project has a <code>pom.xml</code> file in the root directory. Here's a basic example:</p>

      <pre>
        <code>
{`<project xmlns="http://maven.apache.org/POM/4.0.0"
         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd">
    <modelVersion>4.0.0</modelVersion>

    <groupId>com.example</groupId>
    <artifactId>my-maven-project</artifactId>
    <version>1.0-SNAPSHOT</version>

    <properties>
        <maven.compiler.source>11</maven.compiler.source>
        <maven.compiler.target>11</maven.compiler.target>
    </properties>

    <dependencies>
        <!-- Add your project dependencies here -->
    </dependencies>

    <build>
        <plugins>
            <plugin>
                <groupId>org.apache.maven.plugins</groupId>
                <artifactId>maven-compiler-plugin</artifactId>
                <version>3.8.1</version>
            </plugin>
        </plugins>
    </build>
</project>`}
        </code>
      </pre>

      <p>This pom.xml file defines the project structure, dependencies, and build configuration for your Maven project.</p>

      <h4 className="darkText">Step 3: Create a GitHub Actions Workflow for Maven Build and SAST Scan</h4>
      <p>Create a file named <code>maven-build-and-sast.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: Maven Build and SAST Check

on:
  push:
    branches:
      - main
  pull_request:
    branches:
      - main

jobs:
  build-and-sast:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v3

      - name: Set up JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'
          java-version: '11'

      - name: Cache Maven packages
        uses: actions/cache@v3
        with:
          path: ~/.m2/repository
          key: \${{ runner.os }}-maven-\${{ hashFiles('**/pom.xml') }}
          restore-keys: |
            \${{ runner.os }}-maven-

      - name: Install dependencies
        run: mvn install -DskipTests=true

      - name: Build application
        run: mvn package

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SAST Scan
        id: sast
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=New Scan from GitHub Actions" \\
            -F "language=java" \\
            https://sast-backend.intruceptlabs.com/api/v1/integrations/performSASTScan)
          
          echo "SAST Response: $RESPONSE"
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV

      - name: Check SAST result
        if: env.canProceed != 'true'
        run: |
          echo "SAST scan failed. Build cancelled."
          exit 1

      - name: Deploy or further steps
        if: env.canProceed == 'true'
        run: |
          echo "SAST scan passed. Proceed with further steps or deployment."
          # Add deployment steps or other actions here`}
        </code>
      </pre>

      <h4 className="darkText">Step 4: Create a GitHub Actions Workflow for Maven Build and SCA Scan</h4>
      <p>Create a file named <code>maven-build-and-sca.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: Maven Build and SCA Check

on:
  push:
    branches:
      - main
  pull_request:
    branches:
      - main

jobs:
  build-and-sca:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v3

      - name: Set up JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'
          java-version: '11'

      - name: Cache Maven packages
        uses: actions/cache@v3
        with:
          path: ~/.m2/repository
          key: \${{ runner.os }}-maven-\${{ hashFiles('**/pom.xml') }}
          restore-keys: |
            \${{ runner.os }}-maven-

      - name: Install dependencies
        run: mvn install -DskipTests=true

      - name: Build application
        run: mvn package

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SCA Scan
        id: sca
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=SCA Scan from GitHub Actions" \\
            -F "language=java" \\
            https://sast-backend.intruceptlabs.com/api/v1/integrations/performSCAScan)
          
          echo "SCA Response: $RESPONSE"
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV

      - name: Check SCA result
        if: env.canProceed != 'true'
        run: |
          echo "SCA scan failed. Build cancelled."
          exit 1

      - name: Deploy or further steps
        if: env.canProceed == 'true'
        run: |
          echo "SCA scan passed. Proceed with further steps or deployment."
          # Add deployment steps or other actions here`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the Workflows</h4>
      <p>These GitHub Actions workflows for Apache Maven build with SAST and SCA scanning do the following:</p>
      <ol>
        <li>Trigger on push or pull request to the main branch.</li>
        <li>Set up the build environment (JDK).</li>
        <li>Cache Maven dependencies for faster builds.</li>
        <li>Install dependencies and build the project using Maven (which uses the pom.xml file).</li>
        <li>Create a zip file of the project for scanning.</li>
        <li>Perform the respective scan (SAST or SCA) and check the result.</li>
        <li>Proceed with deployment or further steps if the scan passes.</li>
      </ol>

      <p><strong>Note:</strong> Remember to adjust the Java version, branch names, and any other project-specific settings to match your project's requirements. Also, add your actual deployment steps or other actions in the final step of each workflow.</p>
    </div>
  );
};

export default MavenGitHubActionsIntegration;