import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { FaRegCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import ResultAPIsIntegration from './resultAPIs'


import { CopyToClipboard } from 'react-copy-to-clipboard';
import BugzillaTicketingIntegration from "./ticketing/bugzillaTicketing";
import AzureDevOpsTicketingIntegration from "./ticketing/azureDevopsTicketing";
import GitHubTicketingIntegration from "./ticketing/githubTicketing";
import JIRATicketingIntegration from "./ticketing/jiraTicketing";


const TicketingIntegrations = () => {

  const navigate = useNavigate()
  const [organization, setOrganization] = useState({})
  const [onLoading, setOnLoading] = useState(false);

  const [clientId, setClientId] = useState('');
  const [clientIdCopied, setClientIdCopied] = useState(false);

  const [clientSecret, setClientSecret] = useState('');
  const [clientSecretCopied, setClientSecretCopied] = useState(false);

  const toaster = useRef()

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {

    window.scrollTo(0, 0);

    loadOrganizationDetails();

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadOrganizationDetails = async (theScanId) => {

    setOnLoading(true);

    const data = {
      scanId: theScanId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/organizations/getOrganizationDetails/', {
      headers: { Authorization: `Bearer ${token}` },
    });

    setOrganization(response.data.organization);
    setClientId(response.data.organization.clientId)
    setClientSecret(response.data.organization.clientSecret)

    setOnLoading(false);

  };


  const notifyClientIdCopied = async () => {

    toast('Client ID copied to the clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }

  const notifyClientSecretCopied = async () => {

    toast('Client secret copied to clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }



  return (

    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '100%' }}>     

        
      <Tabs className="addAgentPage">

            <TabList style={{ borderWidth: 0, display: 'flex', justifyContent: 'flex-start' }}>

            <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>JIRA Ticketing Integration</span>
              </Tab>

              <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>Azure Devops Ticketing Integration</span>
              </Tab>

              <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>Bugzilla Ticketing Integration</span>
              </Tab>

              <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>GitHub Issues Ticketing Integration</span>
              </Tab>             
             
            </TabList>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <JIRATicketingIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <AzureDevOpsTicketingIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

            <BugzillaTicketingIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <GitHubTicketingIntegration/>

            </TabPanel>           
           

        </Tabs>
      </div>
    </div>
  )
}

export default TicketingIntegrations