import React from 'react';

const GitLabCIIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitLab CI/CD Integration Instructions for SAST Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitLab CI/CD</h4>
      <p>Ensure that your GitLab repository has CI/CD set up. If not, you may need to add a GitLab Runner to your project.</p>

      <h4 className="darkText">Step 2: Create a GitLab CI/CD Pipeline for SAST Scan</h4>
      <p>Create a file named <code>.gitlab-ci.yml</code> in the root of your project repository with the following content:</p>

      <pre>
        <code>
{`stages:
  - build
  - test
  - deploy

variables:
  NODE_VERSION: "18"  # Adjust this to your project's Node.js version

build:
  stage: build
  image: node:\${NODE_VERSION}
  script:
    - npm ci
    - npm run build
  artifacts:
    paths:
      - build/  # Adjust this to your build output directory
    expire_in: 1 hour

sast:
  stage: test
  image: alpine:latest
  script:
    - apk add --no-cache curl zip jq
    - zip -r project.zip src package.json package-lock.json -x "*.git*"
    - |
      RESPONSE=$(curl -X POST \\
        -H "Client-ID: 123e4567-e89b-12d3-a456-426614174001" \\
        -H "Client-Secret: 7a91d1c9-2583-4ef6-8907-7c974f1d6a0e" \\
        -H "Content-Type: multipart/form-data" \\
        -F "projectZipFile=@project.zip" \\
        -F "applicationId=65e07ecef30e83d820b00d55" \\
        -F "scanName=New Scan from GitLab CI" \\
        -F "language=javascript" \\
        https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan)
      echo "Response: $RESPONSE"
      CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed // false')
      echo "Can Proceed: $CAN_PROCEED"
      if [ "$CAN_PROCEED" != true ]; then 
        echo "SAST scan failed. Deployment cancelled."
        exit 1
      fi

deploy:
  stage: deploy
  image: node:\${NODE_VERSION}
  script:
    - npm install -g vercel
    - vercel --token="j0fPHc1SaksJcDN9moJIE9bo" --prod --confirm
  only:
    - main  # Adjust this to your main branch name`}
        </code>
      </pre>

      <p>Replace placeholder values with actual values specific to your setup.</p>

      <h4 className="darkText">Explanation of the SAST Pipeline</h4>
      <p>This GitLab CI/CD pipeline for SAST scanning does the following:</p>
      <ol>
        <li>Defines three stages: build, test, and deploy.</li>
        <li>In the build stage:
          <ul>
            <li>Installs dependencies using npm.</li>
            <li>Builds the application.</li>
          </ul>
        </li>
        <li>In the test stage:
          <ul>
            <li>Performs a SAST scan:
              <ul>
                <li>Creates a zip file of the project.</li>
                <li>Sends a POST request to the SAST API with the project zip and necessary credentials.</li>
                <li>Parses the response to determine if the pipeline can proceed.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>In the deploy stage:
          <ul>
            <li>Installs Vercel CLI.</li>
            <li>Deploys to Vercel if the SAST scan passes.</li>
          </ul>
        </li>
      </ol>
      <p>This pipeline ensures that only code that passes the SAST scan is deployed, enhancing the security of your deployment process.</p>

      <h3 className="darkText">GitLab CI/CD Integration Instructions for SCA Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitLab CI/CD</h4>
      <p>Ensure that your GitLab repository has CI/CD set up, as mentioned in the SAST section.</p>

      <h4 className="darkText">Step 2: Create a GitLab CI/CD Pipeline for SCA Scan</h4>
      <p>Create or modify the <code>.gitlab-ci.yml</code> file in the root of your project repository with the following content:</p>

      <pre>
        <code>
{`stages:
  - build
  - test
  - deploy

variables:
  NODE_VERSION: "18"  # Adjust this to your project's Node.js version

build:
  stage: build
  image: node:\${NODE_VERSION}
  script:
    - npm ci
    - npm run build
  artifacts:
    paths:
      - build/  # Adjust this to your build output directory
    expire_in: 1 hour

sca:
  stage: test
  image: alpine:latest
  script:
    - apk add --no-cache curl zip jq
    - zip -r project.zip src package.json package-lock.json -x "*.git*"
    - |
      RESPONSE=$(curl -X POST \\
        -H "Client-ID: 123e4567-e89b-12d3-a456-426614174001" \\
        -H "Client-Secret: 7a91d1c9-2583-4ef6-8907-7c974f1d6a0e" \\
        -H "Content-Type: multipart/form-data" \\
        -F "projectZipFile=@project.zip" \\
        -F "applicationId=65e07ecef30e83d820b00d55" \\
        -F "scanName=SCA Scan from GitLab CI" \\
        -F "language=javascript" \\
        https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSCAScan)
      echo "Response: $RESPONSE"
      CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed // false')
      echo "Can Proceed: $CAN_PROCEED"
      if [ "$CAN_PROCEED" != true ]; then 
        echo "SCA scan failed. Deployment cancelled."
        exit 1
      fi

deploy:
  stage: deploy
  image: node:\${NODE_VERSION}
  script:
    - npm install -g vercel
    - vercel --token="j0fPHc1SaksJcDN9moJIE9bo" --prod --confirm
  only:
    - main  # Adjust this to your main branch name`}
        </code>
      </pre>

      <p>Replace placeholder values with actual values specific to your setup.</p>

      <h4 className="darkText">Explanation of the SCA Pipeline</h4>
      <p>This GitLab CI/CD pipeline for SCA scanning follows a similar structure to the SAST pipeline:</p>
      <ol>
        <li>Defines three stages: build, test, and deploy.</li>
        <li>In the build stage:
          <ul>
            <li>Installs dependencies using npm.</li>
            <li>Builds the application.</li>
          </ul>
        </li>
        <li>In the test stage:
          <ul>
            <li>Performs an SCA scan:
              <ul>
                <li>Creates a zip file of the project.</li>
                <li>Sends a POST request to the SCA API with the project zip and necessary credentials.</li>
                <li>Parses the response to determine if the pipeline can proceed.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>In the deploy stage:
          <ul>
            <li>Installs Vercel CLI.</li>
            <li>Deploys to Vercel if the SCA scan passes.</li>
          </ul>
        </li>
      </ol>
      <p>This pipeline ensures that only code that passes the SCA scan is deployed, helping to identify and manage vulnerabilities in your project's dependencies.</p>

      <p><strong>Note:</strong> Both pipelines use hardcoded values for API keys and tokens. In a production environment, it's recommended to use GitLab's CI/CD variables or other secure methods to manage sensitive information.</p>
    </div>
  );
};

export default GitLabCIIntegration;