import React, { useState, useEffect } from "react";
import axios from 'axios';

const OWASPDistribution = () => {
  const [owaspData, setOwaspData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get(`/api/v1/users/getOWASPDistribution`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOwaspData(response.data);
    } catch (error) {
      console.error("Error fetching OWASP distribution:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const owaspCategories = [
    { id: 'A01:2021', description: 'Broken Access Control' },
    { id: 'A02:2021', description: 'Cryptographic Failures' },
    { id: 'A03:2021', description: 'Injection' },
    { id: 'A04:2021', description: 'Insecure Design' },
    { id: 'A05:2021', description: 'Security Misconfiguration' },
    { id: 'A06:2021', description: 'Vulnerable and Outdated Components' },
    { id: 'A07:2021', description: 'Identification and Authentication Failures' },
    { id: 'A08:2021', description: 'Software and Data Integrity Failures' },
    { id: 'A09:2021', description: 'Security Logging and Monitoring Failures' },
    { id: 'A10:2021', description: 'Server-Side Request Forgery (SSRF)' }
  ];

  return (
    <div className="dashboardCard" style={{
      display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
      borderRadius: 10, padding: 20, width: '48%'
    }}>
      <span style={{ fontSize: 15 }}>OWASP DISTRIBUTION</span>
      <hr style={{ borderColor: '#fff' }} />

      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <table>
          <thead>
            <tr>
              <th style={{ padding: 5 }}>OWASP Top 10 Category</th>
              <th style={{ padding: 5 }}>Affected Applications</th>
              <th style={{ padding: 5 }}>Related Open Findings</th>
            </tr>
          </thead>
          <tbody>
            {owaspCategories.map(category => (
              <tr key={category.id}>
                <td>
                  <div style={{ backgroundColor: '#fff', color: '#000', padding: 5, borderRadius:10 }}>
                    {`${category.id} - ${category.description}`}
                  </div>
                </td>
                <td>{owaspData[category.id]?.affectedApplications.length || 0}</td>
                <td>{owaspData[category.id]?.relatedOpenFindings || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
 <style>
        {`
          .spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border-left-color: #09f;
            animation: spin 1s ease infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>

    </div>
  );
};

export default OWASPDistribution;