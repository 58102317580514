import React from 'react';

const CircleCIIntegration = () => {

  return (
    <div>
    <h3 className="darkText">CircleCI Integration Instructions for SAST Scan</h3>
    <hr/>

    <h4 className="darkText">Step 1: Configure API Credentials</h4>
    <p>Ensure you have your <strong>Client-ID</strong> and <strong>Client-Secret</strong> ready. You will use these in your CircleCI pipeline configuration.</p>

    <h4 className="darkText">Step 2: Create a Shell Script to Perform SAST Scan</h4>
    <p>Create a shell script named <code>performSASTScan.sh</code> and save it in your project repository:</p>
    <pre>
      <code>
        {`#!/bin/bash

# Variables
PROJECT_DIR=$1
SCAN_NAME=$2
APPLICATION_ID=$3
CLIENT_ID=$4
CLIENT_SECRET=$5
API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan"

# Create a zip file of the project
ZIP_FILE="project.zip"
cd $PROJECT_DIR
zip -r $ZIP_FILE . -x@.gitignore

# Perform SAST scan
RESPONSE=$(curl -X POST $API_URL \\
  -H "Client-ID: $CLIENT_ID" \\
  -H "Client-Secret: $CLIENT_SECRET" \\
  -F "projectZipFile=@$ZIP_FILE" \\
  -F "scanName=$SCAN_NAME" \\
  -F "applicationId=$APPLICATION_ID" 

# Parse the response
CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')

# Return the result
if [ "$CAN_PROCEED" == "true" ]; then
  echo "Scan successful. Proceeding with the pipeline."
  exit 0
else
  echo "Scan failed. Stopping the pipeline."
  exit 1
fi`}
      </code>
    </pre>

    <h4 className="darkText">Step 3: Update CircleCI Configuration (.circleci/config.yml)</h4>
    <p>In your CircleCI project, add a configuration file named <code>.circleci/config.yml</code> with the following content:</p>
    <pre>
      <code>
        {`version: 2.1

jobs:
perform_sast_scan:
  docker:
    - image: circleci/node:14.17.0  # Choose your preferred Docker image

  steps:
    - checkout

    - run:
        name: Perform SAST Scan
        command: |
          chmod +x performSASTScan.sh
          ./performSASTScan.sh $CIRCLE_WORKING_DIRECTORY Your_Scan_Name Your_Application_ID Your_Project_Language $CLIENT_ID $CLIENT_SECRET

workflows:
version: 2
build_and_scan:
  jobs:
    - perform_sast_scan`}
      </code>
    </pre>

    <h4 className="darkText">Step 4: Set Environment Variables</h4>
    <p>Set environment variables for <strong>CLIENT_ID</strong> and <strong>CLIENT_SECRET</strong> in your CircleCI project settings.</p>

  

    <h4 className="darkText">Example CircleCI Configuration</h4>
    <pre>
      <code>
        {`version: 2.1

jobs:
perform_sast_scan:
  docker:
    - image: circleci/node:14.17.0  # Choose your preferred Docker image

  steps:
    - checkout

    - run:
        name: Perform SAST Scan
        command: |
          chmod +x performSASTScan.sh
          ./performSASTScan.sh $CIRCLE_WORKING_DIRECTORY Your_Scan_Name Your_Application_ID Your_Project_Language $CLIENT_ID $CLIENT_SECRET

workflows:
version: 2
build_and_scan:
  jobs:
    - perform_sast_scan`}
      </code>
    </pre>

    <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, <code>INTRUCEPT_Scan_Name</code>, and <code>INTRUCEPT_APPLICATION_ID</code>, with actual values specific to your setup.</p>

    
    </div>
  );
};

export default CircleCIIntegration;