import React from 'react';

const AzureDevOpsIntegration = () => {
  return (
    <div>
      <h2 className="darkText">Azure DevOps Pipeline Integration Instructions for Node.js with SAST and SCA Scans</h2>
      <hr/>

      <h3 className="darkText">SAST Scan Integration</h3>

      <h4 className="darkText">Step 1: Configure Azure Pipeline for SAST</h4>
      <p>Create a file named <code>azure-pipelines-sast.yml</code> in the root of your project repository with the following content:</p>

      <pre>
        <code>
{`trigger:
  - main

pool:
  vmImage: 'ubuntu-latest'

steps:
- task: NodeTool@0
  inputs:
    versionSpec: '18'
  displayName: 'Install Node.js'

- script: |
    npm ci
    npm run build
  displayName: 'npm install and build'

- task: ArchiveFiles@2
  inputs:
    rootFolderOrFile: '$(System.DefaultWorkingDirectory)'
    includeRootFolder: false
    archiveType: 'zip'
    archiveFile: '$(Build.ArtifactStagingDirectory)/trikaratech-project.zip' 
    replaceExistingArchive: true
  displayName: 'Create project zip'

- script: |
    RESPONSE=$(curl -X POST \\
      -H "Client-ID: $(INTRUCEPT_CLIENT_ID)" \\
      -H "Client-Secret: $(INTRUCEPT_CLIENT_SECRET)" \\
      -F "projectZipFile=@$(Build.ArtifactStagingDirectory)/trikaratech-project.zip" \\
      -F "applicationId=$(INTRUCEPT_APPLICATION_ID)" \\
      -F "scanName=Trikaratech SAST Scan from Azure Pipeline" \\
      -F "language=javascript" \\
      https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan)
    
    echo "SAST Response: $RESPONSE"
    
    CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
    echo "##vso[task.setvariable variable=canProceed;isOutput=true]$CAN_PROCEED"
  displayName: 'Perform SAST Scan'
  name: sastScan

- script: |
    echo "SAST scan failed. Deployment cancelled."
    exit 1
  condition: and(succeeded(), ne(variables['sastScan.canProceed'], 'true'))
  displayName: 'Check SAST result'

- task: PublishBuildArtifacts@1
  inputs:
    pathToPublish: '$(Build.ArtifactStagingDirectory)/trikaratech-project.zip'
    artifactName: 'drop'
  displayName: 'Publish Artifact'
  condition: and(succeeded(), eq(variables['sastScan.canProceed'], 'true'))
`}
        </code>
      </pre>

      <h3 className="darkText">SCA Scan Integration</h3>

      <h4 className="darkText">Step 1: Configure Azure Pipeline for SCA</h4>
      <p>Create a file named <code>azure-pipelines-sca.yml</code> in the root of your project repository with the following content:</p>

      <pre>
        <code>
{`trigger:
  - main

pool:
  vmImage: 'ubuntu-latest'

steps:
- task: NodeTool@0
  inputs:
    versionSpec: '18'
  displayName: 'Install Node.js'

- script: |
    npm ci
    npm run build
  displayName: 'npm install and build'

- task: ArchiveFiles@2
  inputs:
    rootFolderOrFile: '$(System.DefaultWorkingDirectory)'
    includeRootFolder: false
    archiveType: 'zip'
    archiveFile: '$(Build.ArtifactStagingDirectory)/trikaratech-project.zip' 
    replaceExistingArchive: true
  displayName: 'Create project zip'

- script: |
    RESPONSE=$(curl -X POST \\
      -H "Client-ID: $(INTRUCEPT_CLIENT_ID)" \\
      -H "Client-Secret: $(INTRUCEPT_CLIENT_SECRET)" \\
      -F "projectZipFile=@$(Build.ArtifactStagingDirectory)/trikaratech-project.zip" \\
      -F "applicationId=$(INTRUCEPT_APPLICATION_ID)" \\
      -F "scanName=Trikaratech SCA Scan from Azure Pipeline" \\
      -F "language=javascript" \\
      https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSCAScan)
    
    echo "SCA Response: $RESPONSE"
    
    CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
    echo "##vso[task.setvariable variable=canProceed;isOutput=true]$CAN_PROCEED"
  displayName: 'Perform SCA Scan'
  name: scaScan

- script: |
    echo "SCA scan failed. Deployment cancelled."
    exit 1
  condition: and(succeeded(), ne(variables['scaScan.canProceed'], 'true'))
  displayName: 'Check SCA result'

- task: PublishBuildArtifacts@1
  inputs:
    pathToPublish: '$(Build.ArtifactStagingDirectory)/trikaratech-project.zip'
    artifactName: 'drop'
  displayName: 'Publish Artifact'
  condition: and(succeeded(), eq(variables['scaScan.canProceed'], 'true'))
`}
        </code>
      </pre>

      <h4 className="darkText">Step 2: Configure Azure DevOps Project</h4>
      <ol>
        <li>Go to your Azure DevOps project.</li>
        <li>Navigate to <strong>Pipelines</strong> {'>'} <strong>New Pipeline</strong>.</li>
        <li>Choose <strong>GitHub</strong> as the location of your code.</li>
        <li>Select your repository.</li>
        <li>Choose <strong>Existing Azure Pipelines YAML file</strong>.</li>
        <li>Select the <code>azure-pipelines-sast.yml</code> file for SAST pipeline or <code>azure-pipelines-sca.yml</code> for SCA pipeline.</li>
        <li>Review the pipeline and click <strong>Run</strong>.</li>
        <li>Repeat the process for the other pipeline.</li>
      </ol>

      <h4 className="darkText">Step 3: Configure Secrets</h4>
      <p>In your Azure DevOps project, you need to configure the following secrets:</p>
      <ol>
        <li>Go to <strong>Pipelines</strong> {'>'} <strong>Library</strong>.</li>
        <li>Create a new variable group or edit an existing one.</li>
        <li>Add the following secrets:
          <ul>
            <li><strong>INTRUCEPT_CLIENT_ID</strong>: Your INTRUCEPT client ID</li>
            <li><strong>INTRUCEPT_CLIENT_SECRET</strong>: Your INTRUCEPT client secret</li>
            <li><strong>INTRUCEPT_APPLICATION_ID</strong>: Your INTRUCEPT application ID</li>
          </ul>
        </li>
        <li>Link the variable group to your pipelines.</li>
      </ol>

      <h4 className="darkText">Explanation of the Pipelines</h4>
      <p>These Azure DevOps pipelines for a Node.js project with SAST and SCA scanning do the following:</p>
      <ol>
        <li>Trigger on push to the main branch.</li>
        <li>Set up the build environment (Node.js).</li>
        <li>Install dependencies and build the project.</li>
        <li>Create a zip file of the project for scanning.</li>
        <li>Perform the respective scan (SAST or SCA) and check the result.</li>
        <li>If the scan passes, publish the build artifact.</li>
      </ol>

      <p><strong>Note:</strong> The main difference between the SAST and SCA pipelines is the API endpoint used for scanning. SAST uses <code>performSASTScan</code> while SCA uses <code>performSCAScan</code>. Ensure that you have the necessary permissions and subscriptions to use both services.</p>
    </div>
  );
};

export default AzureDevOpsIntegration;