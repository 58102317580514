import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import '../style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineApps } from "react-icons/md";
import { MdOutlineFindInPage } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";

import Chart from 'react-apexcharts';

const Top10UniqueApplicationFindings = () => {

  const [loadingStats, setLoadingStats] = useState(false)

  const [result, setResult] = useState([])
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);

  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/users/getTop10UniqueApplicationFindings`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setResult(response.data)

    setOnLoading(false);
  }; 

  console.log('result:',result)

  
  return (
    <>

<div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>TOP 10 UNIQUE APPLICATION FINDINGS</span>
            <hr style={{ borderColor: '#fff' }} />



            <table style={{ width: '100%' }}>

<thead>

  <th style={{ padding: 15 }}>Finding</th>
  <th style={{ padding: 15 }}>Count</th> 


</thead>

<tbody>


  {result && result.map((item, index) => (
    <tr>
      <td style={{ textAlign: 'left', fontSize: 13, fontWeight: 'normal' }}>
        {item.finding}
      </td>
      <td style={{ textAlign: 'left', fontSize: 13, fontWeight: 'normal' }}>
        {item.count}
      </td>
    </tr>

  ))}










</tbody>


</table>





          </div>
    </>
  )
}

export default Top10UniqueApplicationFindings