import React from 'react';

const JenkinsIntegration = () => {

  return (
    <div>
    <h3 className="darkText">Jenkins Integration Instructions for SAST Scan</h3>
    <hr/>

    <h4 className="darkText">Step 1: Configure Jenkins Credentials</h4>
    <ol>
      <li>Go to Jenkins dashboard.</li>
      <li>Click on <strong>Credentials</strong> {'->'} <strong>System</strong> {'->'} <strong>Global credentials (unrestricted)</strong>.</li>
      <li>Click on <strong>Add Credentials</strong>.</li>
      <li>Add <strong>Client-ID</strong> and <strong>Client-Secret</strong> as <strong>Secret text</strong> or <strong>Username and Password</strong> if preferred.</li>
    </ol>

    <h4 className="darkText">Step 2: Create a Shell Script to Perform SAST Scan</h4>
    <p>Create a shell script named <code>performSASTScan.sh</code> and save it in your project repository:</p>
    <pre>
      <code>
        {`#!/bin/bash

# Variables
PROJECT_DIR=$1
SCAN_NAME=$2
APPLICATION_ID=$3
CLIENT_ID=$4
CLIENT_SECRET=$5
API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan"

# Create a zip file of the project
ZIP_FILE="project.zip"
cd $PROJECT_DIR
zip -r $ZIP_FILE . -x@.gitignore

# Perform SAST scan
RESPONSE=$(curl -X POST $API_URL \\
  -H "Client-ID: $CLIENT_ID" \\
  -H "Client-Secret: $CLIENT_SECRET" \\
  -F "projectZipFile=@$ZIP_FILE" \\
  -F "scanName=$SCAN_NAME" \\
  -F "applicationId=$APPLICATION_ID"

# Parse the response
CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')

# Return the result
if [ "$CAN_PROCEED" == "true" ]; then
  echo "Scan successful. Proceeding with the pipeline."
  exit 0
else
  echo "Scan failed. Stopping the pipeline."
  exit 1
fi`}
      </code>
    </pre>

    <h4 className="darkText">Step 3: Update Jenkins Pipeline (Jenkinsfile)</h4>
    <p>In your <code>Jenkinsfile</code>, add a new stage to perform the SAST scan using the shell script:</p>
    <pre>
      <code>
        {`pipeline {
  agent any

  environment {
      // Define your environment variables here
      CLIENT_ID = credentials('your-client-id-credential-id')
      CLIENT_SECRET = credentials('your-client-secret-credential-id')
  }

  stages {
      stage('Checkout') {
          steps {
              checkout scm
          }
      }
      stage('Perform SAST Scan') {
          steps {
              script {
                  def scanName = "Your_Scan_Name" // Customize this
                  def applicationId = "Your_Application_ID" // Customize this
                  def language = "Your_Project_Language" // Customize this
                  
                  sh """
                      chmod +x performSASTScan.sh
                      ./performSASTScan.sh \${env.WORKSPACE} \${scanName} \${applicationId} \${language} \${env.CLIENT_ID} \${env.CLIENT_SECRET}
                  """
              }
          }
      }
      // Add more stages as needed
  }
}`}
      </code>
    </pre>

    <h4 className="darkText">Example Jenkinsfile</h4>
    <pre>
      <code>
        {`pipeline {
  agent any

  environment {
      CLIENT_ID = credentials('your-client-id-credential-id')
      CLIENT_SECRET = credentials('your-client-secret-credential-id')
  }

  stages {
      stage('Checkout') {
          steps {
              checkout scm
          }
      }
      stage('Perform SAST Scan') {
          steps {
              script {
                  def scanName = "Your_Scan_Name"
                  def applicationId = "Your_Application_ID"
                  
                  sh """
                      chmod +x performSASTScan.sh
                      ./performSASTScan.sh \${env.WORKSPACE} \${scanName} \${applicationId} \${language} \${env.CLIENT_ID} \${env.CLIENT_SECRET}
                  """
              }
          }
      }
      // Add more stages as needed
  }
}`}
      </code>
    </pre>

    <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, <code>INTRUCEPT_Scan_Name</code>, and <code>INTRUCEPT_APPLICATION_ID</code>, with actual values specific to your setup.</p>

    
      </div>
  );
};

export default JenkinsIntegration;