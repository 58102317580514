import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import '../style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineApps } from "react-icons/md";
import { MdOutlineFindInPage } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";

import Chart from 'react-apexcharts';

const CWETop25MostDangerous = () => {


  const [result, setResult] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);

  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/users/getCWETop25MostDangerousSoftwareErrors`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setResult(response.data)

    setOnLoading(false);
  }; 




  const cweLabels = Object.keys(result);
  const cweValues = Object.values(result);

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 400,
            toolbar: {
                show: true
            },
            
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed:true,
                columnWidth: '50%' 
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: cweLabels,
            labels: {
                show: true
            }
        },
        yaxis: {
            labels: {
                show: true
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8']
    };

    const series = [{
        data: cweValues
    }];


  


  return (
    <>
        <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>CWE TOP 25 MOST DANGEROUS SOFTWARE ERRORS</span>
            <hr style={{ borderColor: '#fff' }} />              



                                <Chart options={chartOptions} series={series} type="bar" height="90%"/>




          </div>
    </>
  )
}

export default CWETop25MostDangerous