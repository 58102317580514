import React from 'react';

const JetBrainsTeamCityIntegration = () => {

  return (
    <div>
      <h3 className="darkText">JetBrains TeamCity Integration Instructions for SAST Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure API Credentials</h4>
      <p>Ensure you have your <strong>Client-ID</strong> and <strong>Client-Secret</strong> ready. You will use these in your TeamCity configuration.</p>

      <h4 className="darkText">Step 2: Create a Build Configuration</h4>
      <p>In your TeamCity project, create a new Build Configuration to perform the SAST scan.</p>
      <ul>
        <li><strong>Name:</strong> Perform SAST Scan</li>
        <li><strong>Description:</strong> Execute SAST scan via API</li>
        <li><strong>VCS Roots:</strong> Configure your version control settings as per your project.</li>
        <li><strong>Build Steps:</strong> Add a new build step to execute the SAST scan script:</li>
        <ul>
          <li><strong>Runner Type:</strong> Command Line</li>
          <li><strong>Step Name:</strong> Perform SAST Scan</li>
          <li><strong>Run:</strong> Custom Script</li>
          <li><strong>Custom Script:</strong> Use the following script:
            <pre><code>
              {`# Variables
PROJECT_DIR=%teamcity.build.checkoutDir%
SCAN_NAME=Your_Scan_Name
APPLICATION_ID=Your_Application_ID
LANGUAGE=Your_Project_Language
CLIENT_ID=INTRUCEPT_CLIENT_ID
CLIENT_SECRET=INTRUCEPT_CLIENT_SECRET
API_URL=https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan

# Create a zip file of the project
cd %PROJECT_DIR%
zip -r project.zip . -x@.gitignore

# Perform SAST scan
curl -X POST %API_URL% \\
    -H "Client-ID: %CLIENT_ID%" \\
    -H "Client-Secret: %CLIENT_SECRET%" \\
    -F "projectZipFile=@project.zip" \\
    -F "scanName=%SCAN_NAME%" \\
    -F "applicationId=%APPLICATION_ID%" \\
    -F "language=%LANGUAGE%"
`}
            </code></pre>

            
            <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, <code>INTRUCEPT_Scan_Name</code>, and <code>INTRUCEPT_APPLICATION_ID</code>, with actual values specific to your setup.</p>

          
          </li>
          <li><strong>Execution Directory:</strong> <code>%teamcity.build.checkoutDir%</code></li>
        </ul>
      </ul>

      <h4 className="darkText">Step 3: Run the Build Configuration</h4>
      <p>Trigger the Build Configuration to perform the SAST scan on your projects in TeamCity.</p>

      <h4 className="darkText">Step 4: Review Scan Results</h4>
      <p>Review the scan results and logs within TeamCity to monitor the progress and outcome of the SAST scan.</p>

     
    </div>
  );
};

export default JetBrainsTeamCityIntegration;