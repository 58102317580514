import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

import axios from 'axios';

import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import zIndex from "@mui/material/styles/zIndex";
import { borderColor } from "@mui/system";
import { CircularProgress } from '@mui/material';

import { IoMdArrowRoundBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import { CgNotes } from "react-icons/cg";
import { Link } from "react-router-dom";
import GaugeChart from 'react-gauge-chart'
import { FaCheckCircle } from "react-icons/fa";

import Chart from 'react-apexcharts'
import { sanitizeSortModel } from "@mui/x-data-grid/hooks/features/sorting/gridSortingUtils";
import { FaRegCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Application = () => {

  const location = useLocation();

  const navigate = useNavigate()
  const [applicationId, setApplicationId] = useState('')
  const [userId, setUserId] = useState('')
  const [application, setApplication] = useState({})
  const [onLoading, setOnLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [applicationIdCopied, setApplicationIdCopied] = useState(false);

  const [exportingPDF, setExportingPDF] = useState(false);

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )

  const customStyles = {
    content: {
      top: '20%',
      left: '10%',
      width: '80%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      borderColor: 'yellow'
    },
  };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };


  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {

    window.scrollTo(0, 0);

    setOnLoading(true);

    var arr = location.search.split('=');

    var theApplicationId = arr[1];

    setApplicationId(theApplicationId);

    loadApplicationDetails(theApplicationId);

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadApplicationDetails = async (theAppId) => {

    setOnLoading(true)

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/applications/getApplicationDetails/' + theAppId, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setApplication(response.data.application);

    setOnLoading(false);

  };


  const handleCheckboxChange = (settingName, event) => {
    const isChecked = event.target.checked;
    updatePrefs(application._id, settingName, isChecked);
};  
 


const updatePrefs = async (applicationId, settingName, isEnabled) => {
  try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.post('api/v1/organizations/updateAppPrefs', 
      {
          applicationId,  // Pass application._id as applicationId
          [settingName]: isEnabled === 'true' || isEnabled === true,  // Ensuring boolean
      }, 
      {
          headers: { 
              Authorization: `Bearer ${token}`  // Authorization header with token
          },
      });

      console.log('Preferences updated successfully:', response.data);
  } catch (error) {
      console.error('Error updating preferences:', error);

      if (error.response) {
          console.error('Response error:', error.response.data);
      }
  }
};



  const closeModal = async () => {

    setModalIsOpen(false);
  };

  const goBack = async () => {

    navigate('/applications')
  }

  const notifyApplicationIdCopied = async () => {

    toast('Application ID copied to the clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }

  const getLabelText = (value) => {

    if (value >= 0 && value <= 25) {
      return 'LOW';
    } else if (value > 25 && value <= 50) {
      return 'MEDIUM';
    } else if (value > 50 && value <= 75) {
      return 'HIGH';
    } else if (value > 75 && value <= 100) {
      return 'CRITICAL';
    } else {
      return '';
    }
  };


  const scansChartOptions = {
    labels: ['Queued', 'Running', 'Completed', 'Failed'],
    colors: ['#d3d8de', '#fde140', '#07be64', '#eb0a05'],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
      formatter: function(seriesName, opts) {
        // Ensure that opts and opts.w are defined before accessing properties
        if (opts && opts.w && opts.w.globals && opts.w.globals.series) {
          const value = opts.w.globals.series[opts.seriesIndex];
          return seriesName + ": " + (value !== undefined ? value.toString() : '0');
        }
        return seriesName + ": 0";
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
  };

  const scansChartSeries = [0, application.runningScansCount, application.completedScansCount, application.failedScansCount];

  console.log('scansChartSeries:', scansChartSeries)

  const issuesChartOptions = {
    labels: ['Critical', 'High', 'Medium', 'Low', 'Informational'],
    colors: ['#a0090f', '#fb5550', '#ffad3b', '#ffdc48', '#d3d8de'],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
      formatter: function(seriesName, opts) {
        // Ensure that opts and opts.w are defined before accessing properties
        if (opts && opts.w && opts.w.globals && opts.w.globals.series) {
          const value = opts.w.globals.series[opts.seriesIndex];
          return seriesName + ": " + (value !== undefined ? value.toString() : '0');
        }
        return seriesName + ": 0";
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
  };

  const issuesChartSeries = [application.criticalIssueCount, application.highIssueCount,
  application.mediumIssueCount, application.lowIssueCount, application.informationalIssueCount];

  console.log('issuesChartSeries:', issuesChartSeries)


  var riskRatingVal = 0;

  if (application.riskRating == 'CRITICAL') {

    riskRatingVal = 0.80;

  } else if (application.riskRating == 'HIGH') {

    riskRatingVal = 0.60;

  } else if (application.riskRating == 'MEDIUM') {

    riskRatingVal = 0.40;

  } else if (application.riskRating == 'LOW') {

    riskRatingVal = 0.15;

  }


  let commonIssueTypesChartOptions;
  let commonIssueTypesChartSeries;

  console.log('commonIssueTypesChartSeries:', commonIssueTypesChartSeries)


  if (application && application.mostCommonIssues && application.mostCommonIssues.length > 0) {

    // Initialize empty arrays
    const labels = [];
    const series = [];
    const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd'];

    // Loop through the mostCommonIssues array and populate labels and series arrays
    application.mostCommonIssues.forEach((issue, index) => {
        if (index < 5) {  // Limit to a maximum of 5 items
            labels.push(issue.issue);
            series.push(issue.count);
        }
    });

    commonIssueTypesChartOptions = {
        labels: labels,
        colors: colors.slice(0, labels.length),  // Use only the necessary number of colors
        legend: {
            position: 'bottom',
            formatter: function(seriesName, opts) {
              // Ensure that opts and opts.w are defined before accessing properties
              if (opts && opts.w && opts.w.globals && opts.w.globals.series) {
                const value = opts.w.globals.series[opts.seriesIndex];
                return seriesName + ": " + (value !== undefined ? value.toString() : '0');
              }
              return seriesName + ": 0";
            },
            onItemHover: {
              highlightDataSeries: false
            }
        },
    };

    commonIssueTypesChartSeries = series;
}


console.log('application:',application)


  return (
    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>

      <>

        {onLoading ?
          <ShimmerTable row={8} col={10} />
          :

          <div style={{
            width: '100%', marginLeft: '0%', marginRight: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>

            <div style={{ marginBottom: '2rem', }}>
              <h2>{application.name}</h2>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 13, color: 'white' }}>Application ID : {application._id}</span>
                <CopyToClipboard text={applicationId}
                  onCopy={() => notifyApplicationIdCopied()}>

                  <FaRegCopy color="#fff" style={{ cursor: 'pointer', marginLeft: 20 }} />

                </CopyToClipboard>
              </div>

              <hr />
              <span style={{ fontSize: 15, color: 'white' }}>Application Type : <span style={{fontWeight:'bold'}}>{application.type}</span> </span><br />
              <span style={{ fontSize: 15, color: 'white' }}>Business Owner : <span style={{fontWeight:'bold'}}>{application.businessOwner ? (application.businessOwner.firstName + ' ' + application.businessOwner.firstName) : ''}</span></span><br />
              <span style={{ fontSize: 15, color: 'white' }}>Business Unit : <span style={{fontWeight:'bold'}}>{application.businessUnit ? application.businessUnit.name : ''}</span></span><br />
              <span style={{ fontSize: 15, color: 'white' }}>Asset Group : <span style={{fontWeight:'bold'}}>{application.assetGroup ? application.assetGroup.name : ''}</span></span><br />
              <hr />


            </div>


            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#ffffff',
                  borderWidth: 1,
                  color: '#ffffff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} /> Back to Applications
              </CButton>


            </div>
          </div>

        }


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: 30 }}>

          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column', height: 400,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }

            {!onLoading && riskRatingVal !== 0 ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'center' }}>Risk Rating</h4>
                  <hr />

                  <GaugeChart id="gauge-chart3"
                    nrOfLevels={4}
                    colors={['#a4ba0d', '#fec81a', '#f85356', '#a10b0d']}
                    arcWidth={0.3}
                    percent={riskRatingVal}
                    formatTextValue={(value) => getLabelText(value)}
                  />


                </div>


              </div>
              :
              <>
                <h4 style={{ color: '#fff', textAlign: 'center' }}>Risk Rating</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>



          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }

            {!onLoading && !(scansChartSeries[0] == 0 && scansChartSeries[1] == 0 && scansChartSeries[2] == 0 && scansChartSeries[3] == 0) ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'center' }}>Scans</h4>
                  <hr />

                  <Chart
                    options={scansChartOptions}
                    series={scansChartSeries}
                    type="donut"
                    height={300}
                  />


                </div>


              </div>
              :
              <>
                <h4 style={{ color: '#fff', textAlign: 'center' }}>Scans</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>



          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading && !(issuesChartSeries[0] == 0 && issuesChartSeries[1] == 0 && issuesChartSeries[2] == 0 && issuesChartSeries[3] == 0 && issuesChartSeries[4] == 0) ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'center' }}>Issues</h4>
                  <hr />

                  <Chart
                    options={issuesChartOptions}
                    series={issuesChartSeries}
                    type="donut"
                    height={300}
                  />



                </div>
              </div>
              :
              <>
                <h4 style={{ color: '#fff', textAlign: 'center' }}>Issues</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>



          <div style={{
            width: '60%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading && commonIssueTypesChartSeries ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10, }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'center' }}>Most Common</h4>
                  <hr />

                  <Chart
                    options={commonIssueTypesChartOptions}
                    series={commonIssueTypesChartSeries}
                    type="donut"
                    height={300}
                  />

                </div>
              </div>

              :

              <>

                <h4 style={{ color: '#fff', textAlign: 'center' }}>Most Common</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>


          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading &&


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'center' }}>Policies</h4>
                  <hr />

                  <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', alignSelf: 'center',
                    width:300
                   }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width:300 }}>
                <input
                    type="checkbox"
                    id="one"
                    defaultChecked={application.owaspTop10Enabled}
                    onChange={(event) => handleCheckboxChange('owaspTop10Enabled', event)}
                    style={{ marginRight: 10, width:50,marginTop:5  }}
                />
                <label for="one" style={{ color: '#fff', display:'inline' }}>OWASP Top 10</label>
            </div>

            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'flex-start',  width:300 }}>
                <input
                    type="checkbox"
                    id="two"
                    defaultChecked={application.sans25Enabled}
                    onChange={(event) => handleCheckboxChange('sans25Enabled', event)}
                    style={{ marginRight: 10,width:50, marginTop:5 }}
                />
                <label for="two" style={{ color: '#fff', display:'inline' }}>SANS 25</label>
            </div>

            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width:300 }}>
                <input
                    type="checkbox"
                    id="three"
                    defaultChecked={application.pciDSSEnabled}
                    onChange={(event) => handleCheckboxChange('pciDSSEnabled', event)}
                    style={{ marginRight: 10, width:50,marginTop:5  }}
                />
                <label for="three" style={{ color: '#fff',display:'inline' }}>PCI DSS</label>
            </div>
        </form>

                </div>
              </div>

            }

          </div>


          <div style={{
            width: '100%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 800,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading &&


              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'left' }}>Recent SAST Scans</h4>
                  <hr />


                  {application.fiveRecentSASTScans && application.fiveRecentSASTScans.map((scan, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '10px 0' }}>
                      <Link to={`/sast-scan-detail?id=${scan._id}`} style={{ color: '#fff', backgroundColor: 'transparent', marginRight: '10px' }}>{scan.scanName}</Link>
                      <span style={{ fontSize: 13 }}>{scan.scanCompletedAt ? 'Completed' : 'Running'}</span>
                    </div>
                  ))}




                </div>
                <hr />


                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'left' }}>Recent SCA Scans</h4>
                  <hr style={{ borderColor: '#e50202' }} />


                  {application.fiveRecentSCAScans && application.fiveRecentSCAScans.map((scan, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '10px 0' }}>
                      <Link to={`/sca-scan-detail?id=${scan._id}`} style={{ color: '#fff', backgroundColor: 'transparent', marginRight: '10px' }}>{scan.scanName}</Link>
                      <span style={{ fontSize: 13 }}>{scan.scanCompletedAt ? 'Completed' : 'Running'}</span>
                    </div>
                  ))}




                </div>

                <hr style={{ borderColor: '#e50202' }} />

                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#fff', textAlign: 'left' }}>Recent DAST Scans</h4>
                  <hr />


                  {application.fiveRecentDASTScans && application.fiveRecentDASTScans.map((scan, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '10px 0' }}>
                      <Link to={`/dast-scan-detail?id=${scan._id}`} style={{ color: '#fff', backgroundColor: 'transparent', marginRight: '10px' }}>{scan.scanName}</Link>
                      <span style={{ fontSize: 13 }}>{scan.scanCompletedAt ? 'Completed' : 'Running'}</span>
                    </div>
                  ))}




                </div>





              </div>



            }

            {application.fiveRecentScans && application.fiveRecentScans.length == 0 &&
              <>
                <span style={{ textAlign: 'center', alignSelf: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>

        </div>

      </>

    </div>
  )
}

export default Application



