import React from 'react';

const GitHubActionsIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitHub Actions Integration Instructions for SAST Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <ol>
        <li>Go to your GitHub repository.</li>
        <li>Click on <strong>Settings</strong>.</li>
        <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong>.</li>
        <li>Click on <strong>New repository secret</strong>.</li>
        <li>Add secrets for <strong>INTRUCEPT_CLIENT_ID</strong>, <strong>INTRUCEPT_CLIENT_SECRET</strong> and <strong>INTRUCEPT_APPLICATION_ID</strong>.</li>
      </ol>

      <h4 className="darkText">Step 2: Create a GitHub Actions Workflow to Perform SAST Scan</h4>
      <p>Create a yml file named <code>deploy.yml</code> and save it into the folder <code>.github/workflows</code> directory inside your project repository:</p>
      <p>The following is an example where the deployment target is Vercel:</p>
      <pre>
        <code>
{`name: Deploy to Vercel with SAST Check

on:
  push:
    branches:
      - main  # Adjust this to your main branch name

jobs:
  sast-check-and-deploy:
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v3

      - name: Set up Node.js
        uses: actions/setup-node@v3
        with:
          node-version: '18'  # Adjust this to your project's Node.js version

      - name: Install dependencies
        run: npm ci

      - name: Build application
        run: npm run build

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SAST Scan
        id: sast
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=New Scan from API Github actions" \\
            -F "language=javascript" \\
            https://sast-backend.intruceptlabs.com/api/v1/integrations/performSASTScan)
          
          echo "SAST Response: $RESPONSE"
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV

      - name: Check SAST result
        if: steps.sast.outputs.canProceed != 'true'
        run: |
          echo "SAST scan failed. Deployment cancelled."
          exit 1

      - name: Deploy to Vercel
        if: steps.sast.outputs.canProceed == 'true'
        env:
          VERCEL_TOKEN: \${{ secrets.VERCEL_TOKEN }}
        run: |
          npx vercel --token="\${{ secrets.VERCEL_TOKEN }}" --prod --confirm`}
        </code>
      </pre>

      <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, and <code>INTRUCEPT_APPLICATION_ID</code> with actual values specific to your setup.</p>

      <h4 className="darkText">Explanation of the Workflow</h4>
      <p>This GitHub Actions workflow does the following:</p>
      <ol>
        <li>Triggers on push to the main branch.</li>
        <li>Sets up the Node.js environment and installs dependencies.</li>
        <li>Builds the application.</li>
        <li>Creates a zip file of the project, excluding Git-related files.</li>
        <li>Performs a SAST scan using the Intrucept API:</li>
        <ul>
          <li>Sends a POST request with the project zip file and necessary credentials.</li>
          <li>Parses the response to determine if the deployment can proceed.</li>
        </ul>
        <li>If the SAST scan passes, it deploys to Vercel.</li>
        <li>If the SAST scan fails, it cancels the deployment and exits with an error.</li>
      </ol>
      <p>This workflow ensures that only code that passes the SAST scan is deployed, enhancing the security of your deployment process.</p>

      <h3 className="darkText">GitHub Actions Integration Instructions for SCA Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <p>Use the same secrets as configured for the SAST scan.</p>

      <h4 className="darkText">Step 2: Create a GitHub Actions Workflow to Perform SCA Scan</h4>
      <p>Create a yml file named <code>sca-scan.yml</code> and save it into the folder <code>.github/workflows</code> directory inside your project repository:</p>
      <pre>
        <code>
{`name: SCA Scan and Deploy

on:
  push:
    branches:
      - main  # Adjust this to your main branch name

jobs:
  sca-check-and-deploy:
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v3

      - name: Set up Node.js
        uses: actions/setup-node@v3
        with:
          node-version: '18'  # Adjust this to your project's Node.js version

      - name: Install dependencies
        run: npm ci

      - name: Build application
        run: npm run build

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SCA Scan
        id: sca
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=SCA Scan from GitHub Actions" \\
            -F "language=javascript" \\
            https://sca-backend.intruceptlabs.com/api/v1/integrations/performSCAScan)
          
          echo "SCA Response: $RESPONSE"
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV

      - name: Check SCA result
        if: steps.sca.outputs.canProceed != 'true'
        run: |
          echo "SCA scan failed. Deployment cancelled."
          exit 1

      - name: Deploy to Vercel
        if: steps.sca.outputs.canProceed == 'true'
        env:
          VERCEL_TOKEN: \${{ secrets.VERCEL_TOKEN }}
        run: |
          npx vercel --token="\${{ secrets.VERCEL_TOKEN }}" --prod --confirm`}
        </code>
      </pre>

      <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, and <code>INTRUCEPT_APPLICATION_ID</code> with actual values specific to your setup.</p>

      <h4 className="darkText">Explanation of the SCA Workflow</h4>
      <p>This GitHub Actions workflow for SCA scanning follows the same structure as the SAST workflow:</p>
      <ol>
        <li>Triggers on push to the main branch.</li>
        <li>Sets up the Node.js environment and installs dependencies.</li>
        <li>Builds the application.</li>
        <li>Creates a zip file of the project, excluding Git-related files.</li>
        <li>Performs an SCA scan using the Intrucept API:</li>
        <ul>
          <li>Sends a POST request with the project zip file and necessary credentials.</li>
          <li>Parses the response to determine if the deployment can proceed.</li>
        </ul>
        <li>If the SCA scan passes, it deploys to Vercel.</li>
        <li>If the SCA scan fails, it cancels the deployment and exits with an error.</li>
      </ol>
      <p>This workflow ensures that only code that passes the SCA scan is deployed, helping to identify and manage vulnerabilities in your project's dependencies.</p>
    </div>
  );
};

export default GitHubActionsIntegration;