import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import lightLogo from '../../../assets/images/intrucept-light-logo.svg'
import { useDispatch } from 'react-redux'
import showPassword from '../../../assets/images/show-password.png'
import hidePassword from '../../../assets/images/hide-password.png'
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from '@coreui/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { role } = useParams()

  // Add this useEffect hook for the authentication check
  useEffect(() => {
    const user = localStorage.getItem('ASIUser');
    if (user) {
      navigate('/user-dashboard');
    }
  }, [navigate]);

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [submitting, setSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const [isEmailOrPasswordEmpty, setIsEmailOrPasswordEmpty] = useState(false)
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false)
  const [isEmailInvalid, setIsEmailInvalid] = useState(false)
  const [isEmailOrPasswordIncorrect, setIsEmailOrPasswordIncorrect] = useState(false)
  const [showingPassword, setShowingPassword] = useState(false);  

  const toggleShowPassword = () => {
    setShowingPassword(!showingPassword)
  }

  const goHome = (e) => {
    e.preventDefault();
    navigate('/')
  }

  function validateEmail(input) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return input.match(validRegex);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      login();
    }
  };

  const login = async () => {
    if (email.length == 0 || password.length == 0) {
      setIsError(true);
      setErrorMessage('Please enter email and password');
    } else if(!validateEmail(email)){
      setIsError(true);
      setErrorMessage('Please enter a valid email');
    } else {
      setSubmitting(true);
      const url = '/api/v1/users/userLogin';
      const data = {
        email: email,
        password: password,
      };

      axios.post(url, data)
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.user) {              
              localStorage.setItem('ASIUser', JSON.stringify(response.data.data.user))
              localStorage.setItem('ASIToken', response.data.data.token);
              setSubmitting(false);
              if(response.data.data.user.userType == 'user'){
                navigate('/user-dashboard');
              }else if(response.data.data.user.userType == 'admin'){
                navigate('/admin-all-users');
              }  
            }
          } else {
            setIsError(true);
            setErrorMessage('These credentials do not match our records')
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            setSubmitting(false);
            setIsError(true);
            setErrorMessage('These credentials do not match our records')
          } else {
            setSubmitting(false);
            setIsError(true);
            setErrorMessage('Something went wrong. Please try again later')
          }
        });
    }
  }

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center" style={{  backgroundColor: '#1d222e' }}>
      <CContainer style={{  backgroundColor: '#1d222e' }}>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4 border  border-end-0" style={{backgroundColor:'#fefeff'}}>
                <CCardBody >
                  <CForm style={{ display: 'flex', flexDirection: 'column', width:'70%', marginLeft:'15%', justifyContent:'center' }}>
                    <img src={lightLogo} style={{ width: '80%', alignSelf: 'center' }} alt="" />
                    <text className="login-page-card-heading" style={{ marginTop: '1rem', textAlign: 'center', color:'#343434', fontSize:20, fontWeight:'bold' }}>
                        AppSecOps Portal
                    </text>
                    <text className="login-page-card-heading" style={{ marginTop: '1rem', textAlign: 'center', color:'#000', fontSize:17, fontWeight:'bold' }}>
                        Login to Your Account
                    </text>
                    <CInputGroup className="mb-3 mt-3" style={{ flexDirection: 'column', marginTop: 30 }}>
                      <CFormInput
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={handleKeyPress}
                        autoComplete="username"
                        className="blackText"
                        style={{ width: '100%' }}
                      />
                      {isEmailInvalid &&
                        <span style={{ color: 'red', fontSize: 12, marginTop: 5 }}>Invalid email</span>
                      }
                    </CInputGroup>
                    <CInputGroup className="mb-4" style={{ flexDirection: 'column', }}>
                      <CFormInput
                        type={showingPassword ? 'text' : 'password'}
                        placeholder="Password"
                        autoComplete="current-Password"
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={handleKeyPress}
                        className="blackText"
                        style={{ width: '100%' }}
                      />
                      <img src={!showingPassword ? showPassword : hidePassword} style={{
                        width: 30,
                        height: 30, position: 'absolute', right: 10, top: 5, zIndex: 1000
                      }} onClick={toggleShowPassword} />
                      {isError &&
                         <span style={{ color: 'red', fontSize: 12, marginTop: 5 }}>{errorMessage}</span> 
                      }                     
                    </CInputGroup>
                    <CRow>
                      <CCol xs={60}>
                        <CButton
                          style={{
                            width: '100%', marginBottom: '2%', borderWidth: 0, fontSize: 20,
                            background: 'linear-gradient(135deg, #161c2a, #1a2130)'
                          }}
                          color="primary"
                          className="px-3 "
                          onClick={() => {
                            login()
                          }}
                        >
                          {submitting ?
                            <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                            :
                            'Login'
                          }
                        </CButton>
                        <Link to={'/#'} style={{ textDecoration: 'none', display:'none' }}>
                          <p style={{ color: '#2f2f3b', fontSize: 14 }}>Forgot password ?</p>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login;