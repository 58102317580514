import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom'

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ShimmerTable } from "react-shimmer-effects";


const EditUser = () => {

  const navigate = useNavigate()
  const location = useLocation();

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [collectionUrl, setCollectionUrl] = useState("")
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])
  const [language, setLanguage] = useState('')

  const [users, setUsers] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [assetGroups, setAssetGroups] = useState([])

  const [user, setUser] = useState({})
  const [userId, setUserId] = useState('')

  const [userType, setUserType] = useState('user')
  const [businessUnit, setBusinessUnit] = useState('')
  const [theLocation, setTheLocation] = useState('')
  const [theStatus, setTheStatus] = useState('Active')
  const [url, setURL] = useState('')
  const [host, setHost] = useState('')
  const [description, setDescription] = useState('')

  const [validationFailed, setValidationFailed] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();
  
  const statuses = ['Active', 'Suspended'] 

  function checkURLType(url) {

    try {
      new URL(url);
    } catch (error) {
      return false; // Invalid URL
    }

    const extension = url.split('.').pop().toLowerCase();

    if (extension === 'json') {
      return true; // Valid URL to a JSON file
    } else if (extension === 'yaml' || extension === 'yml') {
      return true; // Valid URL to a YAML file
    } else {
      return false; // Valid URL, but not a JSON or YAML file
    }
  }


  useEffect(() => {

    window.scrollTo(0, 0);

    //setOnLoading(true);

    var arr = location.search.split('=');

    var theUserId = arr[1];

    setUserId(theUserId);

    loadUserDetails(theUserId);

  }, []);
  

  useEffect(() => {

    fetchUsers();
    fetchBusinessUnits();

  }, []);


  const loadUserDetails = async (theUserId) => {


    setLoading(true);

    const data = {
      userId: theUserId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/organizations/getUserDetails/'+theUserId, {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(response.data.data.firstName)


    setUser(response.data.data);
    setFirstName(response.data.data.firstName)
    setLastName(response.data.data.lastName)
    setEmail(response.data.data.email)
    setRole(response.data.data.role)
    setPhoneNumber(response.data.data.phoneNumber)
    setBusinessUnit(response.data.data.businessUnit)
    setTheLocation(response.data.data.location)
    setTheStatus(response.data.data.status)
    

    setLoading(false);

  };


  const fetchUsers = async () => {

    setLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/getOrganizationUsers`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setUsers(response.data.users);  


    setLoading(false);
  };


  const fetchBusinessUnits = async () => {

    setLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/getBusinessUnits`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setBusinessUnits(response.data.businessUnits);

    if (response.data.businessUnits.length > 0) {
      setBusinessUnit(response.data.businessUnits[0]._id)
    } else {
      setBusinessUnit('');
    }


    setLoading(false);
  };


  const editApplication = () => {

    if (firstName === '') {

      setValidationFailed(true);
      setErrorText('First name is required.');

    } else if (lastName === '') {

      setValidationFailed(true);
      setErrorText('Last name is required.');

    }if (email === '') {

      setValidationFailed(true);
      setErrorText('Email is required.');

    }if (phoneNumber === '') {

      setValidationFailed(true);
      setErrorText('Phone number is required.');
    }else{


      setSubmitting(true)

      const bearerToken = localStorage.getItem('ASIToken');

      // Create a FormData object
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('role', role);
      formData.append('phoneNumber', phoneNumber);
      formData.append('businessUnit', businessUnit);
      formData.append('userType', userType);
      formData.append('location', theLocation);  
      formData.append('status', theStatus);    
     

      const requestData = {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        phoneNumber: phoneNumber,
        businessUnit: businessUnit,
        userType: userType,
        location: theLocation,   
        status:theStatus    
    };


      // Make the API call
      fetch(global.backendUrl + '/api/v1/organizations/editUser', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {

          // Handle the API response

          if (data.hasOwnProperty('error')) {
            setValidationFailed(true);
            setErrorText(data.error);
            setSubmitting(false);
          }
          else if (data.hasOwnProperty('err')) {
            setSubmitting(false);
            setValidationFailed(true);
            setErrorText("Something went wrong. Please try again");

          } else {

            setSubmissionSuccess(true);
            setSubmitting(false);

            toast('User updated', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });


            navigate('/users')
          }

        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });      

    }

  }


  const goBack = (e) => {

    e.preventDefault();
    navigate('/users')
  }

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }



  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>

      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h2>Editing User</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Users
            </CButton>
          </div>

          {loading ?
            <ShimmerTable row={8} col={10} />
          :



          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>


          <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>User Role</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="role"
                className="white-input"
                onChange={(e) => setRole(e.target.value)}
                value={role}
                style={{ width: '100%' }}
              >
                 <option key="Administrator" value="Administrator">
                    Administrator
                  </option>

                  <option key="Application Manager" value="Application Manager">
                    Application Manager
                  </option>

                  <option key="Tester" value="Tester">
                    Tester
                  </option>
              
              </CFormSelect>
            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>First Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="First Name"
                autoComplete="firstName"
                className="white-input"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                style={{ width: '100%' }}
              />


            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Last Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Last Name"
                autoComplete="lastName"
                className="white-input"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Email</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Email"
                autoComplete="email"
                className="white-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Phone Number</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Phone Number"
                autoComplete="phoneNumber"
                className="white-input"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>        



            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessUnit"
                className="white-input"
                onChange={(e) => setBusinessUnit(e.target.value)}
                value={businessUnit}
                style={{ width: '100%' }}
              >
                {businessUnits.map(businessUnit => (
                  <option key={businessUnit._id} value={businessUnit._id}>
                    {businessUnit.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Location</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Location"
                autoComplete="location"
                className="white-input"
                value={theLocation}
                onChange={(e) => setTheLocation(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>

          


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Status</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="status"
                className="white-input"
                onChange={(e) => setTheStatus(e.target.value)}
                value={theStatus}
                style={{ width: '100%' }}
              >
                {statuses.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>


            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={editApplication}
              disabled={loading}
            >


              {submitting ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save User'
              }


            </CButton>

          </div>
}


        </div>
      </div>

    </div>
  )
}

export default EditUser



