import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoCodescan } from "react-icons/go";
import axios from 'axios';


const StartSCAScan = () => {

  const navigate = useNavigate()

  const [scanName, setScanName] = useState("")
  const [email, setEmail] = useState("")
  const [collectionUrl, setCollectionUrl] = useState("")  
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])
  const [language, setLanguage] = useState('Python')


  const [validationFailed, setValidationFailed] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();

  const languages = [ 'Python', 'C#', 'Java', 'Javascript', 'C', 'C++', 'Typescript', 'Ruby', 'PHP', 'Swift', 'Kotlin', "Go"]


  function checkURLType(url) {

    try {
      new URL(url);
    } catch (error) {
      return false; // Invalid URL
    }
    
    const extension = url.split('.').pop().toLowerCase();
    
    if (extension === 'json') {
      return true; // Valid URL to a JSON file
    } else if (extension === 'yaml' || extension === 'yml') {
      return true; // Valid URL to a YAML file
    } else {
      return false; // Valid URL, but not a JSON or YAML file
    }
  }

  function isExceeding100MB(file){

    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMB > 1000) {
      //console.log('File size exceeds 8MB');
      return true;
    } else {
      //console.log('File size is within 8MB limit');
      return false;
    }
    
  }

  useEffect(() => {    

    fetchApplications();   

  }, []);



  const fetchApplications = async () => {

    setLoading(true);
    
  
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/applications/getAllApplications`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  
    setApplications(response.data.applications);

    if(response.data.applications.length > 0){
      setApplicationId(response.data.applications[0]._id)
    }else{
      setApplicationId('');
    }
   
  
    setLoading(false);
  };



  const startScan = () => {

    if (scanName === '') {

      setValidationFailed(true);
      setErrorText('Scan name is required.');

    }else if (applicationId === '') {

      setValidationFailed(true);
      setErrorText('Please select an application');

    } else if (file === null) {

      setValidationFailed(true);
      setErrorText('Please select a project zip file');

    }else if((file !== null && (typeof file !=='undefined')) && isExceeding100MB(file)){

      setValidationFailed(true);
      setErrorText('The file size must not exceed 100MB. Please attach a smaller file');

    }else {

      setLoading(true)      

      const bearerToken = localStorage.getItem('ASIToken');

      // Create a FormData object
      const formData = new FormData();
      formData.append('scanName', scanName);
      formData.append('projectZipFile', file);
      formData.append('applicationId', applicationId);
      //formData.append('language', language);

      console.log('scanName:',scanName)
      console.log('file:',file)
      console.log('applicationId:',applicationId)
      //console.log('language:', language)

      // Make the API call
      fetch(global.backendUrl+'/api/v1/scaScans/startScaScan', {
        method: 'POST',
        headers: {
        'Authorization': `Bearer ${bearerToken}`
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {

        // Handle the API response

        if(data.hasOwnProperty('error')){
           setValidationFailed(true);
           setErrorText(data.error);
           setLoading(false);
        }
        else if(data.hasOwnProperty('err')){
          setLoading(false);
          setValidationFailed(true);
          setErrorText("Something went wrong. Please try again");
          
       }else{

           setSubmissionSuccess(true);
           setLoading(false);

           toast('Scan started', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
    

           // Move to active scans window in 1 second
           navigate('/sca-scans')
        }

      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });

/*
      const timer = setTimeout(() => {

         //setSubmissionSuccess();
         toast('Scan started', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });


       // Move to active scans window in 1 second
       navigate('/sca-scans')
      }, 10000);    
      
      */

    }   

  }
 

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const goBack = (e) => {

    e.preventDefault();
    navigate('/sca-scans')
  }


  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }



  return (
    <div style={{ display:'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>

      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h2>Start an SCA Scan</h2>
            <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  marginRight:20,
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#fff',
                  borderWidth: 1,
                  color: '#fff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight:10 }} />
                Back to Scan List
              </CButton>
          </div>


          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>

         
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>Scan Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Scan Name"
                autoComplete="scanName"
                className="white-input"
                onChange={(e) => setScanName(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>       

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Select application</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="scanType"
                className="white-input"
                onChange={(e) => setApplicationId(e.target.value)}
                style={{ width: '100%' }}
              >
                {applications.map(application => (
                  <option key={application._id} value={application._id}>
                    {application.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>   


           

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white' }}>Upload the project zip file</CFormLabel><br/>

            <CInputGroup className="" style={{ flexDirection: 'column', }}>
              <CFormInput
                placeholder="Upload the project zip file"
                autoComplete="username"
                type="file" 
                className="white-input"
                size="sm"
                id="inputFile"
                accept="application/zip"
                onChange={handleFileChange}
                style={{ width: '100%' }}
              />


            {validationFailed &&

                <text style={{color:'red', paddingTop:15}}>{errorText}</text>
            }

            {errorText.includes('valid') &&

<>
<br/>
<p>The valid package files for supported frameworks are listed below:-</p>
<ul>

  <li><strong>pom.xml</strong>: For Maven based Java projects</li>
  <li><strong>composer.json</strong>: For Composer based PHP projects</li>
  <li><strong>requirements.txt</strong>: For Python projects</li>
  <li><strong>package.json</strong>: For Javascript/Typescript or other NPM based projects</li>
  <li><strong>Gemfile</strong>: For Ruby projects</li>
  <li><strong>Package.swift</strong>: For Swift projects</li>
  <li><strong>go.mod</strong>: For Go projects</li>
  <li><strong>Podfile</strong>: For CocoaPods projects using Objective C or Swift</li>
  <li><strong>build.gradle</strong>: For Gradle based Java projects</li>
  <li><strong>packages.config, nuget.config, or a file with extension .csproj </strong>: For C# based .NET projects</li>


  </ul>
  </>
            }

            </CInputGroup>          
            


            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={startScan}
              disabled={loading}
            >              


              {loading ?
                            <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                            :
                            'Start Scan'
                          }


            </CButton>

        {submissionSuccess &&
            <span style={{color:'green', fontSize:17}}>The scan is started. 
            After the scan is complete, the scan report is sent to the email mentioned. <br/>It will also be available 
              in the dashboard under Scans menu. You may leave this window now. 
              <br/> <br/>
              Moving to scans list in 5 seconds...
            </span>
        }
          </div>


        </div>
      </div>

    </div>
  )
}

export default StartSCAScan



