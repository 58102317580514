import React from 'react';

const AtlassianBambooIntegration = () => {

  return (
    <div>
      <h3 className="darkText">Atlassian Bamboo Integration Instructions for SAST Scan</h3>

      <hr/>

      <h4 className="darkText">Step 1: Configure API Credentials</h4>
      <p>Ensure you have your <strong>Client ID</strong> and <strong>Client Secret</strong> ready. You will use these in your Bamboo plan configuration.</p>

      <h4 className="darkText">Step 2: Create a Shell Script to Perform SAST Scan</h4>
      <p>Create a shell script named <code>performSASTScan.sh</code> and save it in your project repository:</p>
      <pre>
        <code>
          {`#!/bin/bash

# Variables
PROJECT_DIR=$1
SCAN_NAME=$2
APPLICATION_ID=$3
CLIENT_ID=$4
CLIENT_SECRET=$5
API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan"

# Create a zip file of the project
ZIP_FILE="project.zip"
cd $PROJECT_DIR
zip -r $ZIP_FILE . -x@.gitignore

# Perform SAST scan
RESPONSE=$(curl -X POST $API_URL \\
    -H "Client-ID: $CLIENT_ID" \\
    -H "Client-Secret: $CLIENT_SECRET" \\
    -F "projectZipFile=@$ZIP_FILE" \\
    -F "scanName=$SCAN_NAME" \\
    -F "applicationId=$APPLICATION_ID"

# Parse the response
CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')

# Return the result
if [ "$CAN_PROCEED" == "true" ]; then
    echo "Scan successful. Proceeding with the pipeline."
    exit 0
else
    echo "Scan failed. Stopping the pipeline."
    exit 1
fi`}
        </code>
      </pre>

      <h4 className="darkText">Step 3: Update Bamboo Plan Configuration</h4>
      <p>In your Bamboo plan, add a new script task to perform the SAST scan using the shell script:</p>
      <pre>
        <code>
          {`<task>
  <script>
    <description>Perform SAST Scan</description>
    <interpreter>bin/sh</interpreter>
    <scriptFile>performSASTScan.sh</scriptFile>
    <argument>\${bamboo.working.directory}</argument>
    <argument>\${bamboo.SCAN_NAME}</argument>
    <argument>\${bamboo.APPLICATION_ID}</argument>
    <argument>\${bamboo.CLIENT_ID}</argument>
    <argument>\${bamboo.CLIENT_SECRET}</argument>
  </script>
</task>`}
        </code>
      </pre>

      <h4 className="darkText">Step 4: Configure Bamboo Plan Variables</h4>
      <ol>
        <li>Go to your Bamboo plan configuration.</li>
        <li>Click on <strong>Plan configuration</strong>.</li>
        <li>Navigate to the <strong>Variables</strong> section.</li>
        <li>Add variables for <strong>CLIENT_ID</strong> and <strong>CLIENT_SECRET</strong>.</li>
      </ol>

      

      <h4 className="darkText">Example Script Task Configuration</h4>
      <pre>
        <code>
          {`<task>
  <script>
    <description>Perform SAST Scan</description>
    <interpreter>bin/sh</interpreter>
    <scriptFile>performSASTScan.sh</scriptFile>
    <argument>\${bamboo.working.directory}</argument>
    <argument>Your_Scan_Name</argument>
    <argument>Your_Application_ID</argument>
    <argument>\${bamboo.CLIENT_ID}</argument>
    <argument>\${bamboo.CLIENT_SECRET}</argument>
  </script>
</task>`}
        </code>
      </pre>

      <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, <code>INTRUCEPT_Scan_Name</code>, and <code>INTRUCEPT_APPLICATION_ID</code>, with actual values specific to your setup.</p>
    </div>
  );
};

export default AtlassianBambooIntegration;