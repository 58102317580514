import React from 'react';

const DockerIntegration = () => {

  return (
    <div>
      <h4 className="darkText">Steps to Run SAST Scan Before Docker Image Creation</h4>
      <hr/>
      
      <ol>
        <li>
          <h5 className="darkText">Prepare Your Project</h5>          
        </li>

        <li>
          <h5 className="darkText">Initiate SAST Scan</h5>
          <p>Call the SAST scan API to start the scan. Example using curl:</p>
          <pre>{`
curl -X POST 'https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan' \\
-H 'Client-ID: Your_Client_ID' \\
-H 'Client-Secret: Your_Client_Secret' \\
-H 'Content-Type: application/json' \\
-d '{"applicationId": "your_intrucept_application_id"}'
          `}</pre>
          <p>This will return a response with {'{canProceed: true}'} or {'{canProceed: false}'}.</p>
        </li>

        <li>
          <h5 className="darkText">Evaluate Scan Results</h5>
          <p>Check the 'canProceed' value in the response to determine if the build can proceed.</p>
        </li>

        <li>
          <h5 className="darkText">Proceed with Docker Build</h5>
          <p>If 'canProceed' is true, continue with building the Docker image:</p>
          <pre>{`
if [[ "$canProceed" == "true" ]]; then
    docker build -t your-image-name .
else
    echo "SAST scan failed. Aborting Docker build."
    exit 1
fi
          `}</pre>
        </li>
      </ol>

      <h4 className="darkText">Example Script</h4>
      <pre>{`
#!/bin/bash

# Set your API credentials
CLIENT_ID="Your_Client_ID"
CLIENT_SECRET="Your_Client_Secret"
APPLICATION_ID="your_intrucept_application_id"

# Initiate SAST scan and get results
scan_response=$(curl -s -X POST 'https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan' \\
-H "Client-ID: $CLIENT_ID" \\
-H "Client-Secret: $CLIENT_SECRET" \\
-H 'Content-Type: application/json' \\
-d '{"applicationId": "'$APPLICATION_ID'"}')

# Extract canProceed value
can_proceed=$(echo $scan_response | jq -r '.canProceed')

if [ "$can_proceed" == "true" ]; then
    echo "SAST scan passed. Building Docker image..."
    docker build -t your-image-name .
else
    echo "SAST scan failed. Aborting Docker build."
    exit 1
fi
      `}</pre>

      <p>Note: This script requires 'jq' for JSON parsing. Adjust API endpoints, credentials, and application details as needed.</p>
    </div>
  );
};

export default DockerIntegration;