import React, { useState, useEffect } from "react";
import axios from 'axios';
import { CCol, CRow, CContainer, CButton, CButtonGroup } from '@coreui/react';
import Chart from 'react-apexcharts';

const VulnerabilityTrends = () => {
  const [mode, setMode] = useState('monthly');
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [mode]);

  const fetchData = async () => {
    setIsLoading(true);
  
    const token = localStorage.getItem('ASIToken');
    const cacheKey = `vulnerabilityTrends_${mode}`;
    const cachedData = localStorage.getItem(cacheKey);
  
    if (cachedData) {
      setChartData(JSON.parse(cachedData));
      setIsLoading(false);
    }
  
    try {
      const response = await axios.get(`/api/v1/users/getOrgVulnerabilityTrends?mode=${mode}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      setChartData(response.data);
      localStorage.setItem(cacheKey, JSON.stringify(response.data));
    } catch (error) {
      console.error('Error fetching vulnerability trends:', error);
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access (e.g., redirect to login page)
        // history.push('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      type: 'category',
      categories: chartData ? chartData.labels : [],
      tickPlacement: 'on',
      labels: {
        rotate: -45,
        rotateAlways: true,
        formatter: function(value) {
          if (mode === 'weekly' && value) {
            const parts = value.split('-W');
            if (parts.length === 2) {
              const [year, week] = parts;
              return `Week ${week}, ${year}`;
            }
          }
          return value; // For daily, monthly, or if splitting fails, return as is
        }
      },
      title: {
        text: mode.charAt(0).toUpperCase() + mode.slice(1)
      }
    },
    yaxis: {
      title: {
        text: 'Number of Vulnerabilities'
      },
      min: 0,
      tickAmount: 5
    },
    legend: {
      position: 'top'
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    colors: ['#FF4560', '#00E396', '#FEB019'],
    markers: {
      size: 4
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      theme: 'light',
      x: {
        formatter: function(value) {
          if (mode === 'weekly' && value) {
            const parts = value.split('-W');
            if (parts.length === 2) {
              const [year, week] = parts;
              return `Week ${week}, ${year}`;
            }
          }
          return value;
        }
      },
      y: {
        formatter: function(value) {
          return value + " vulnerabilities";
        }
      }
    }
  };

  const series = chartData ? [
    { name: 'SAST', data: chartData.vulnTrends.SAST },
    { name: 'SCA', data: chartData.vulnTrends.SCA },
    { name: 'DAST', data: chartData.vulnTrends.DAST }
  ] : [];

  return (
    <div className="dashboardCard" style={{
      display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
      borderRadius: 10, padding: 20, width: '100%'
    }}>
      <span style={{ fontSize: 15 }}>VULNERABILITY TRENDS</span>
      <hr style={{ borderColor: '#fff' }} />

      <CContainer>
        <CRow className="mb-3">
          <CCol className="text-right">
            <CButtonGroup>
              <CButton 
                color={mode === 'daily' ? 'primary' : 'secondary'} 
                onClick={() => setMode('daily')}
              >
                Daily
              </CButton>
              <CButton 
                color={mode === 'weekly' ? 'primary' : 'secondary'} 
                onClick={() => setMode('weekly')}
              >
                Weekly
              </CButton>
              <CButton 
                color={mode === 'monthly' ? 'primary' : 'secondary'} 
                onClick={() => setMode('monthly')}
              >
                Monthly
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            {isLoading && !chartData ? (
              <div className="spinner"></div>
            ) : chartData ? (
              <Chart
                options={chartOptions}
                series={series}
                type="line"
                height={350}
              />
            ) : (
              <div>No data available</div>
            )}
          </CCol>
        </CRow>
      </CContainer>

      <style>
        {`
          .spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border-left-color: #09f;
            animation: spin 1s ease infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>

    </div>
  );
};

export default VulnerabilityTrends;