import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import '../style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineApps } from "react-icons/md";
import { MdOutlineFindInPage } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";

import Chart from 'react-apexcharts';

const Top10VulnerableApplications = () => {


  const [loadingStats, setLoadingStats] = useState(false)

  const [items, setItems] = useState([])
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);


  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/users/getTop10VulnerableApplications`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setItems(response.data)

    setOnLoading(false);
  };

  console.log('items:', items)



  return (
    <>


      <div className="dashboardCard" style={{
        display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
        borderRadius: 10, padding: 20, width: '40%'
      }}>


        <span style={{ fontSize: 15 }}>TOP 10 VULNERABLE APPLICATIONS</span>
        <hr style={{ borderColor: '#fff' }} />


        <table style={{ width: '100%' }}>

          <thead>

            <th style={{ padding: 15 }}></th>
            <th style={{ padding: 15 }}>
              <div style={{ backgroundColor: '#FF0000', padding: 5, fontSize: 13, borderRadius: 5, textAlign: 'center' }}>CRITICAL</div>
            </th>
            <th style={{ padding: 15 }}>
              <div style={{ backgroundColor: '#A6001B', padding: 5, fontSize: 13, borderRadius: 5, textAlign: 'center' }}>HIGH</div>
            </th>
            <th style={{ padding: 15 }}>
              <div style={{ backgroundColor: '#FFC300', padding: 5, fontSize: 13, borderRadius: 5, textAlign: 'center', color: '#000', }}>MEDIUM</div>
            </th>
            <th style={{ padding: 15 }}>
              <div style={{ backgroundColor: '#B3FFB3', padding: 5, fontSize: 13, borderRadius: 5, color: '#000', textAlign: 'center' }}>LOW</div>
            </th>
            <th style={{ padding: 15 }}>
              <div style={{ backgroundColor: '#555', padding: 5, fontSize: 13, borderRadius: 5, color: '#fff', textAlign: 'center' }}>INFO</div>
            </th>


          </thead>

          <tbody>


            {items.map((item, index) => (
              <tr>
                <td style={{ textAlign: 'left', fontSize: 13, fontWeight: 'bold' }}>
                  {item.name}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.criticalCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.highCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.mediumCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.lowCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.infoCount}
                </td>
              </tr>

            ))}




          </tbody>


        </table>



      </div>
    </>
  )
}

export default Top10VulnerableApplications