import React from 'react';

const VisualStudioCodeIntegration = () => {

  return (
    <div className="sast-scan-instructions">
       Visual Studio Code
    </div>
  );
};

export default VisualStudioCodeIntegration;