import React from 'react';

const AntGitHubActionsIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitHub Actions Integration Instructions for Apache Ant with SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <ol>
        <li>Go to your GitHub repository.</li>
        <li>Click on <strong>Settings</strong>.</li>
        <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong>.</li>
        <li>Click on <strong>New repository secret</strong>.</li>
        <li>Add secrets for <strong>INTRUCEPT_CLIENT_ID</strong>, <strong>INTRUCEPT_CLIENT_SECRET</strong>, and <strong>INTRUCEPT_APPLICATION_ID</strong>.</li>
      </ol>

      <h4 className="darkText">Step 2: Set up your build.xml file</h4>
      <p>Ensure your project has a <code>build.xml</code> file in the root directory. Here's an example:</p>

      <pre>
        <code>
{`<?xml version="1.0"?>
<project name="SampleProject" default="build" basedir=".">
    <!-- Define properties -->
    <property name="src.dir" value="src"/>
    <property name="build.dir" value="build"/>
    <property name="classes.dir" value="\${build.dir}/classes"/>
    <property name="sast.api.endpoint" value="\${sast.api.endpoint}"/>
    <property name="sast.client.id" value="\${sast.api.key}"/>
    <property name="sast.client.secret" value="\${sast.api.secret}"/>

    <!-- Clean the build directory -->
    <target name="clean">
        <delete dir="\${build.dir}"/>
    </target>

    <!-- Compile the source code -->
    <target name="compile" depends="clean">
        <mkdir dir="\${classes.dir}"/>
        <javac srcdir="\${src.dir}" destdir="\${classes.dir}"/>
    </target>

    <!-- Package the application -->
    <target name="build" depends="compile">
        <mkdir dir="\${build.dir}/dist"/>
        <jar destfile="\${build.dir}/dist/project.jar" basedir="\${classes.dir}"/>
    </target>

    <!-- Perform SAST Scan -->
    <target name="sast" depends="build">
        <echo message="Starting SAST scan..."/>
        <exec executable="curl">
            <arg value="-X"/>
            <arg value="POST"/>
            <arg value="\${sast.api.endpoint}"/>
            <arg value="-H"/>
            <arg value="Client-ID: \${sast.client.id}"/>
            <arg value="-H"/>
            <arg value="Client-Secret: \${sast.client.secret}"/>
            <arg value="-F"/>
            <arg value="projectZipFile=@\${build.dir}/dist/project.jar"/>
            <arg value="-F"/>
            <arg value="applicationId=65e07ecef30e83d820b00d55"/>
            <arg value="-F"/>
            <arg value="scanName=New Scan from Ant build"/>
            <arg value="-F"/>
            <arg value="language=java"/>
            <redirector output="sast_response.json"/>
        </exec>
    </target>
</project>`}
        </code>
      </pre>

      <p>This build.xml file defines targets for cleaning, compiling, building, and performing a SAST scan. The SAST scan target uses curl to send a request to the SAST API endpoint.</p>

      <h4 className="darkText">Step 3: Create a GitHub Actions Workflow for Apache Ant Build and SAST Scan</h4>
      <p>Create a file named <code>ant-build-and-sast.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: Apache Ant Build and SAST Check

on:
  push:
    branches:
      - main
  pull_request:
    branches:
      - main

jobs:
  build-and-sast:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v3

      - name: Set up JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'
          java-version: '11'

      - name: Cache Ant packages
        uses: actions/cache@v3
        with:
          path: ~/.ant
          key: \${{ runner.os }}-ant-\${{ hashFiles('**/build.xml') }}
          restore-keys: |
            \${{ runner.os }}-ant-

      - name: Build with Ant
        run: ant build

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SAST Scan
        id: sast
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=New Scan - Apache Ant - from GitHub Actions" \\
            -F "language=java" \\
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan)
          
          echo "SAST Response: \\$RESPONSE"
          
          CAN_PROCEED=$(echo \\$RESPONSE | jq -r '.canProceed')
          echo "canProceed=\\$CAN_PROCEED" >> \\$GITHUB_ENV

      - name: Check SAST result
        if: env.canProceed != 'true'
        run: |
          echo "SAST scan failed. Build cancelled."
          exit 1

      - name: Deploy or further steps
        if: env.canProceed == 'true'
        run: |
          echo "SAST scan passed. Proceed with further steps or deployment."
          # Add deployment steps or other actions here`}
        </code>
      </pre>

      <h4 className="darkText">Step 4: Create a GitHub Actions Workflow for Apache Ant Build and SCA Scan</h4>
      <p>Create a file named <code>ant-build-and-sca.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: Apache Ant Build and SCA Check

on:
  push:
    branches:
      - main
  pull_request:
    branches:
      - main

jobs:
  build-and-sca:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v3

      - name: Set up JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'
          java-version: '11'

      - name: Cache Ant packages
        uses: actions/cache@v3
        with:
          path: ~/.ant
          key: \${{ runner.os }}-ant-\${{ hashFiles('**/build.xml') }}
          restore-keys: |
            \${{ runner.os }}-ant-

      - name: Build with Ant
        run: ant build

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SCA Scan
        id: sca
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=SCA Scan - Apache Ant - from GitHub Actions" \\
            -F "language=java" \\
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSCAScan)
          
          echo "SCA Response: \\$RESPONSE"
          
          CAN_PROCEED=$(echo \\$RESPONSE | jq -r '.canProceed')
          echo "canProceed=\\$CAN_PROCEED" >> \\$GITHUB_ENV

      - name: Check SCA result
        if: env.canProceed != 'true'
        run: |
          echo "SCA scan failed. Build cancelled."
          exit 1

      - name: Deploy or further steps
        if: env.canProceed == 'true'
        run: |
          echo "SCA scan passed. Proceed with further steps or deployment."
          # Add deployment steps or other actions here`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the Workflows</h4>
      <p>These GitHub Actions workflows for Apache Ant build with SAST and SCA scanning do the following:</p>
      <ol>
        <li>Trigger on push or pull request to the main branch.</li>
        <li>Set up the build environment (JDK).</li>
        <li>Build the project using Apache Ant (which uses the build.xml file).</li>
        <li>Create a zip file of the project for scanning.</li>
        <li>Perform the respective scan (SAST or SCA) and check the result.</li>
        <li>Proceed with deployment or further steps if the scan passes.</li>
      </ol>

      <p><strong>Note:</strong> While the build.xml file includes a SAST scan target, the GitHub Actions workflows are using separate curl commands to perform the scans. This allows for more flexibility and easier integration with the GitHub Actions environment. If you prefer to use the SAST scan target from the build.xml file, you can modify the workflow to call <code>ant sast</code> instead of using the curl command directly.</p>

      <p>Remember to adjust the Java version, branch names, and any other project-specific settings to match your project's requirements. Also, add your actual deployment steps or other actions in the final step of each workflow.</p>
    </div>
  );
};

export default AntGitHubActionsIntegration;