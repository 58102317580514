import React from 'react';

const GradleGitHubActionsIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitHub Actions Integration Instructions for Gradle with SAST Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <ol>
        <li>Go to your GitHub repository.</li>
        <li>Click on <strong>Settings</strong>.</li>
        <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong>.</li>
        <li>Click on <strong>New repository secret</strong>.</li>
        <li>Add secrets for <strong>SAST_CLIENT_ID</strong>, <strong>SAST_CLIENT_SECRET</strong>, and <strong>INTRUCEPT_APPLICATION_ID</strong>.</li>
      </ol>

      <h4 className="darkText">Step 2: Create a GitHub Actions Workflow for Gradle Build and SAST Scan</h4>
      <p>Create a file named <code>gradle-build-and-sast.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: Gradle Build and SAST Check

on:
  push:
    branches:
      - main  # Adjust this to your main branch name
  pull_request:
    branches:
      - main  # Adjust this to your main branch name

jobs:
  build-and-sast:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v3

      - name: Validate Gradle Wrapper
        uses: gradle/wrapper-validation-action@v1

      - name: Set up JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'  # Use 'temurin' for the AdoptOpenJDK distribution
          java-version: '11'        # Adjust to your project's Java version

      - name: Cache Gradle packages
        uses: actions/cache@v3
        with:
          path: ~/.gradle/caches
          key: \${{ runner.os }}-gradle-\${{ hashFiles('**/build.gradle') }}
          restore-keys: |
            \${{ runner.os }}-gradle-

      - name: Ensure gradlew is executable
        run: chmod +x gradlew

      - name: Build and test
        run: ./gradlew build --continue || true

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SAST Scan
        id: sast
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.SAST_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.SAST_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=New Scan - Gradle - from GitHub Actions" \\
            -F "language=java" \\
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan)
          
          echo "SAST Response: $RESPONSE"
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV

      - name: Check SAST result
        if: env.canProceed != 'true'
        run: |
          echo "SAST scan failed. Build cancelled."
          exit 1

      - name: Deploy or further steps
        if: env.canProceed == 'true'
        run: |
          echo "SAST scan passed. Proceed with further steps or deployment."
          # Add deployment steps or other actions here`}
        </code>
      </pre>

      <p>Replace placeholder values with actual values specific to your setup.</p>

      <h4 className="darkText">Explanation of the SAST Workflow</h4>
      <p>This GitHub Actions workflow for Gradle build and SAST scanning does the following:</p>
      <ol>
        <li>Triggers on push or pull request to the main branch.</li>
        <li>Sets up the build environment (JDK, Gradle).</li>
        <li>Builds and tests the project using Gradle.</li>
        <li>Creates a zip file of the project for SAST scanning.</li>
        <li>Performs a SAST scan and checks the result.</li>
        <li>Proceeds with deployment or further steps if the scan passes.</li>
      </ol>

      <h3 className="darkText">GitHub Actions Integration Instructions for Gradle with SCA Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <p>Use the same secrets as configured for the SAST scan.</p>

      <h4 className="darkText">Step 2: Create a GitHub Actions Workflow for Gradle Build and SCA Scan</h4>
      <p>Create a file named <code>gradle-build-and-sca.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: Gradle Build and SCA Check

on:
  push:
    branches:
      - main  # Adjust this to your main branch name
  pull_request:
    branches:
      - main  # Adjust this to your main branch name

jobs:
  build-and-sca:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v3

      - name: Validate Gradle Wrapper
        uses: gradle/wrapper-validation-action@v1

      - name: Set up JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'
          java-version: '11'        # Adjust to your project's Java version

      - name: Cache Gradle packages
        uses: actions/cache@v3
        with:
          path: ~/.gradle/caches
          key: \${{ runner.os }}-gradle-\${{ hashFiles('**/build.gradle') }}
          restore-keys: |
            \${{ runner.os }}-gradle-

      - name: Ensure gradlew is executable
        run: chmod +x gradlew

      - name: Build and test
        run: ./gradlew build --continue || true

      - name: Create project zip
        run: zip -r project.zip . -x "*.git*"

      - name: Perform SCA Scan
        id: sca
        run: |
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: \${{ secrets.SAST_CLIENT_ID }}" \\
            -H "Client-Secret: \${{ secrets.SAST_CLIENT_SECRET }}" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
            -F "scanName=SCA Scan - Gradle - from GitHub Actions" \\
            -F "language=java" \\
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSCAScan)
          
          echo "SCA Response: $RESPONSE"
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV

      - name: Check SCA result
        if: env.canProceed != 'true'
        run: |
          echo "SCA scan failed. Build cancelled."
          exit 1

      - name: Deploy or further steps
        if: env.canProceed == 'true'
        run: |
          echo "SCA scan passed. Proceed with further steps or deployment."
          # Add deployment steps or other actions here`}
        </code>
      </pre>

      <p>Replace placeholder values with actual values specific to your setup.</p>

      <h4 className="darkText">Explanation of the SCA Workflow</h4>
      <p>This GitHub Actions workflow for Gradle build and SCA scanning follows a similar structure to the SAST workflow:</p>
      <ol>
        <li>Triggers on push or pull request to the main branch.</li>
        <li>Sets up the build environment (JDK, Gradle).</li>
        <li>Builds and tests the project using Gradle.</li>
        <li>Creates a zip file of the project for SCA scanning.</li>
        <li>Performs an SCA scan and checks the result.</li>
        <li>Proceeds with deployment or further steps if the scan passes.</li>
      </ol>

      <p><strong>Note:</strong> For both workflows, make sure to adjust the Java version, branch names, and any other project-specific settings to match your project's requirements. Also, remember to add your actual deployment steps or other actions in the final step of each workflow.</p>
    </div>
  );
};

export default GradleGitHubActionsIntegration;