import React from 'react';

const TravisCIIntegration = () => {

  return (
    <div>
    <h3 className="darkText">Travis CI Integration Instructions for SAST Scan</h3>
    <hr/>

    <h4 className="darkText">Step 1: Configure API Credentials</h4>
    <p>Ensure you have your <strong>Client-ID</strong> and <strong>Client-Secret</strong> ready. You will use these in your Travis CI configuration.</p>

    <h4 className="darkText">Step 2: Update Your .travis.yml File</h4>
    <p>Add the following script to your <code>.travis.yml</code> file in your project repository:</p>
    <pre>
      <code>
        {`script:
- PROJECT_DIR=$(pwd)
- SCAN_NAME=Your_Scan_Name
- APPLICATION_ID=Your_Application_ID
- LANGUAGE=Your_Project_Language
- CLIENT_ID=INTRUCEPT_CLIENT_ID
- CLIENT_SECRET=INTRUCEPT_CLIENT_SECRET
- API_URL=https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan

# Create a zip file of the project
- zip -r project.zip . -x@.gitignore

# Perform SAST scan
- >
  curl -X POST $API_URL \\
  -H "Client-ID: $CLIENT_ID" \\
  -H "Client-Secret: $CLIENT_SECRET" \\
  -F "projectZipFile=@project.zip" \\
  -F "scanName=$SCAN_NAME" \\
  -F "applicationId=$APPLICATION_ID"
`}
      </code>
    </pre>

    
    <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, <code>INTRUCEPT_Scan_Name</code>, and <code>INTRUCEPT_APPLICATION_ID</code>, with actual values specific to your setup.</p>


    <h4 className="darkText">Step 3: Trigger the Build</h4>
    <p>Push your changes to the repository to trigger the build on Travis CI.</p>

    <h4 className="darkText">Step 4: Review Build Log</h4>
    <p>Monitor the build log on Travis CI to view the progress and outcome of the SAST scan.</p>

    
  </div>
  );
};

export default TravisCIIntegration;