import React from 'react';

const CodeShipIntegration = () => {

  return (
    <div>
      <h3 className="darkText">CodeShip Integration Instructions for SAST Scan</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure API Credentials</h4>
      <p>Ensure you have your <strong>Client-ID</strong> and <strong>Client-Secret</strong> ready. You will use these in your CodeShip pipeline configuration.</p>

      <h4 className="darkText">Step 2: Create a Shell Script to Perform SAST Scan</h4>
      <p>Create a shell script named <code>performSASTScan.sh</code> and save it in your project repository:</p>
      <pre>
        <code>
          {`#!/bin/bash

# Variables
PROJECT_DIR=$1
SCAN_NAME=$2
APPLICATION_ID=$3
CLIENT_ID=$4
CLIENT_SECRET=$5
API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan"

# Create a zip file of the project
ZIP_FILE="project.zip"
cd $PROJECT_DIR
zip -r $ZIP_FILE . -x@.gitignore

# Perform SAST scan
RESPONSE=$(curl -X POST $API_URL \\
    -H "Client-ID: $CLIENT_ID" \\
    -H "Client-Secret: $CLIENT_SECRET" \\
    -F "projectZipFile=@$ZIP_FILE" \\
    -F "scanName=$SCAN_NAME" \\
    -F "applicationId=$APPLICATION_ID" \\
    -F "language=$LANGUAGE")

# Parse the response
CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')

# Return the result
if [ "$CAN_PROCEED" == "true" ]; then
    echo "Scan successful. Proceeding with the pipeline."
    exit 0
else
    echo "Scan failed. Stopping the pipeline."
    exit 1
fi`}
        </code>
      </pre>

      <h4 className="darkText">Step 3: Update CodeShip Pipeline Configuration (codeship-services.yml)</h4>
      <p>In your CodeShip project, add a pipeline configuration file named <code>codeship-services.yml</code> with the following content:</p>
      <pre>
        <code>
          {`your_service_name:
  image: node:14.17.0  # Choose your preferred Docker image
  encrypted_env_file:
    - env.encrypted

your_step_name:
  service: your_service_name
  command:
    - chmod +x performSASTScan.sh
    - ./performSASTScan.sh $CI_PROJECT_DIR Your_Scan_Name Your_Application_ID Your_Project_Language $CLIENT_ID $CLIENT_SECRET`}
        </code>
      </pre>

      <h4 className="darkText">Step 4: Encrypt Environment Variables</h4>
      <p>Encrypt environment variables <strong>CLIENT_ID</strong> and <strong>CLIENT_SECRET</strong> using CodeShip's CLI tool and store them in an <code>env.encrypted</code> file.</p>

      

      <h4 className="darkText">Example codeship-services.yml</h4>
      <pre>
        <code>
          {`your_service_name:
  image: node:14.17.0  # Choose your preferred Docker image
  encrypted_env_file:
    - env.encrypted

your_step_name:
  service: your_service_name
  command:
    - chmod +x performSASTScan.sh
    - ./performSASTScan.sh $CI_PROJECT_DIR Your_Scan_Name Your_Application_ID Your_Project_Language $CLIENT_ID $CLIENT_SECRET`}
        </code>
      </pre>

      <p>Replace placeholder values like <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, <code>INTRUCEPT_Scan_Name</code>, and <code>INTRUCEPT_APPLICATION_ID</code>, with actual values specific to your setup.</p>

    
    </div>
  );
};

export default CodeShipIntegration;