import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import '../style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineApps } from "react-icons/md";
import { MdOutlineFindInPage } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";

import Chart from 'react-apexcharts';

const Applications = () => {



  const [appStats, setAppStats] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);

  
  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/users/getApplicationsStat`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setAppStats(response.data)

    setOnLoading(false);
  };   

  console.log('appStats:',appStats)


  return (
    <>     


<div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>APPLICATIONS</span>
            <hr style={{ borderColor: '#fff' }} />


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <MdOutlineApps size={50} style={{ color: 'rgb(115, 102, 255)' }} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>{appStats.totalApplicationsScanned}</div>
                  <span style={{ fontSize: 13 }}>Total Applications Scanned</span>
                </div>


              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <AiFillFolderOpen size={50} style={{ color: '#FFA500' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: '#FFA500', fontSize: 25 }}>{appStats.totalApplicationFindings}</div>
                  <span style={{ fontSize: 13 }}>Total Application Findings</span>
                </div>


              </div>

            </div>



          </div>

    </>
  )
}

export default Applications